.enterprise-child {
  position: absolute;
  top: 548px;
  left: 39px;
  filter: blur(150px);
  border-radius: var(--br-5xl);
  background-color: var(--color-whitesmoke-400);
  width: 363px;
  height: 137px;
}
.enterprise-item {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 509px;
  height: 941px;
}
.enterprise-inner {
  position: absolute;
  top: 160px;
  left: 40px;
  max-height: 100%;
  width: 366px;
}
.b,
.group-icon {
  position: absolute;
}
.group-icon {
  top: 40px;
  left: 40px;
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.b {
  top: 75px;
  left: 154px;
  font-size: var(--font-size-21xl);
}
.organizer-page-creation {
  position: absolute;
  top: 2px;
  left: 0;
  font-weight: 500;
}
.group-item {
  position: absolute;
  top: 0;
  left: 400px;
  width: 24px;
  height: 24px;
}
.organizer-page-creation-parent,
.premium-event-listing-parent {
  position: absolute;
  top: 190px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.premium-event-listing-parent {
  top: 232px;
}
.full-custom-registration-forms-parent,
.group-child4,
.in-app-payments-1-cutticket-parent {
  position: absolute;
  top: 274px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.group-child4,
.in-app-payments-1-cutticket-parent {
  top: 316px;
}
.group-child4 {
  top: 42px;
  left: 400px;
  width: 24px;
}
.event-feedback-forms-parent,
.qr-check-ins-unlimited-parent {
  position: absolute;
  top: 358px;
  left: 39px;
  width: 424px;
  height: 66px;
}
.qr-check-ins-unlimited-parent {
  top: 442px;
  height: 24px;
}
.advance-event-analytics {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
}
.advance-event-analytics-wrapper {
  position: absolute;
  top: 402px;
  left: 39px;
  width: 198px;
  height: 20px;
}
.premium-designs-templates-parent,
.virtual-events-unlimited-parent {
  position: absolute;
  top: 484px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.premium-designs-templates-parent {
  top: 526px;
}
.all-discussion-boards-parent,
.email-support-with-24-hr-respo-parent {
  position: absolute;
  top: 568px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.all-discussion-boards-parent {
  top: 610px;
}
.embed-social-media-page-widget-parent,
.leaderboard-ranking-parent {
  position: absolute;
  top: 689px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.leaderboard-ranking-parent {
  top: 730px;
}
.access-to-organizers-virtual-c-parent,
.ads-and-sponserships-parent {
  position: absolute;
  top: 775px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.ads-and-sponserships-parent {
  top: 652px;
}
.join-waitlist,
.rectangle-div {
  position: absolute;
  left: 0;
  width: 362px;
}
.rectangle-div {
  top: 0;
  border-radius: var(--br-42xl);
  background-color: var(--color-darkslategray-500);
  height: 50px;
}
.join-waitlist {
  top: 11px;
  font-weight: 500;
  display: inline-block;
}
.rectangle-parent {
  position: absolute;
  top: 863px;
  left: 73px;
  width: 362px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.month {
  position: absolute;
  top: 94px;
  left: 277px;
  font-weight: 500;
  color: var(--color-darkgray-300);
}
.enterprise,
.pro-item {
  position: absolute;
  top: 1549px;
  left: 785px;
  width: 509px;
  height: 941px;
}
.pro-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  height: 852px;
}
.professional {
  position: absolute;
  top: 47px;
  left: 154px;
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-family: var(--font-poppins);
}
.organizer-page-creation-group,
.priority-event-listing-parent {
  position: absolute;
  top: 190px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.priority-event-listing-parent {
  top: 232px;
}
.advanced-registration-forms-parent,
.in-app-payments-3-cutticket-parent {
  position: absolute;
  top: 274px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.in-app-payments-3-cutticket-parent {
  top: 316px;
}
.event-feedback-forms-group,
.pro-child2,
.qr-check-ins-1000-parent {
  position: absolute;
  top: 358px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.pro-child2,
.qr-check-ins-1000-parent {
  top: 442px;
}
.pro-child2 {
  top: 400px;
  left: 438px;
  width: 24px;
}
.intermediate-event-analytics-wrapper {
  position: absolute;
  top: 402px;
  left: 31px;
  width: 234px;
  height: 20px;
}
.custom-designs-templates-parent,
.virtual-events-8-eventsmonth-parent {
  position: absolute;
  top: 484px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.custom-designs-templates-parent {
  top: 526px;
}
.discussion-boards-speaker-a-parent,
.email-support-with-48-hr-respo-parent {
  position: absolute;
  top: 568px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.discussion-boards-speaker-a-parent {
  top: 610px;
}
.ads-and-sponserships-group,
.embed-social-media-page-widget-group,
.rectangle-group {
  position: absolute;
  top: 689px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.ads-and-sponserships-group,
.rectangle-group {
  top: 652px;
}
.rectangle-group {
  top: 768px;
  left: 73px;
  width: 362px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.month1 {
  position: absolute;
  top: 94px;
  left: 254px;
  font-weight: 500;
  color: var(--color-darkgray-300);
}
.pro,
.starter-item {
  position: absolute;
  top: 1594px;
  left: 57px;
  width: 509px;
  height: 852px;
}
.starter-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  height: 786px;
}
.event-listing-parent,
.organizer-page-creation-container {
  position: absolute;
  top: 190px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.event-listing-parent {
  top: 232px;
}
.enhanced-registration-forms-parent,
.in-app-payments-5-cutticket-parent {
  position: absolute;
  top: 274px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.in-app-payments-5-cutticket-parent {
  top: 316px;
}
.event-feedback-forms-container,
.qr-check-ins-500-parent {
  position: absolute;
  top: 358px;
  left: 40px;
  width: 424px;
  height: 66px;
}
.qr-check-ins-500-parent {
  top: 442px;
  height: 24px;
}
.basic-event-analytics-wrapper {
  position: absolute;
  top: 402px;
  left: 40px;
  width: 171px;
  height: 20px;
}
.virtual-events-5-eventsmonth-parent {
  position: absolute;
  top: 484px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.email-support-with-72-hr-respo-parent,
.limited-custom-designs-templat-parent {
  position: absolute;
  top: 526px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.email-support-with-72-hr-respo-parent {
  top: 568px;
  left: 42px;
}
.group-child38,
.virtual-events-5-eventsmonth-group {
  position: absolute;
  top: 610px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.group-child38 {
  top: 0;
  left: 17px;
  border-radius: var(--br-42xl);
  background-color: var(--color-darkslategray-500);
  width: 362px;
  height: 50px;
}
.rectangle-container {
  position: absolute;
  top: 705px;
  left: 57px;
  width: 379px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.month2 {
  position: absolute;
  top: 96px;
  left: 234px;
  font-weight: 500;
  color: var(--color-darkgray-300);
}
.free-item,
.starter {
  position: absolute;
  top: 509px;
  left: 785px;
  width: 509px;
  height: 786px;
}
.free-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 442px;
  height: 628px;
}
.basic-page-creation-parent,
.group-child39 {
  position: absolute;
  top: 0;
  left: 338px;
  width: 24px;
  height: 24px;
}
.basic-page-creation-parent {
  top: 190px;
  left: 40px;
  width: 362px;
}
.basic-registration-forms-parent,
.event-posting-not-listing-parent {
  position: absolute;
  top: 232px;
  left: 40px;
  width: 362px;
  height: 24px;
}
.basic-registration-forms-parent {
  top: 274px;
}
.basic-post-event-feedback-form-parent,
.in-app-payments-8-cutticket-parent {
  position: absolute;
  top: 316px;
  left: 40px;
  width: 362px;
  height: 24px;
}
.basic-post-event-feedback-form-parent {
  top: 358px;
}
.basic-insights-parent {
  top: 400px;
  left: 40px;
  height: 24px;
}
.basic-insights-parent,
.group-div,
.join-waitlist3 {
  position: absolute;
  width: 362px;
}
.join-waitlist3 {
  top: 11px;
  left: calc(50% - 181px);
  font-weight: 500;
  display: inline-block;
}
.group-div {
  top: 538px;
  left: 40px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.free {
  position: absolute;
  top: 559px;
  left: 164px;
  width: 442px;
  height: 685px;
}
.synclyfy-v1-1-icon {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 1693.5px);
  left: 8px;
  width: 392px;
  height: 200px;
}
.note,
.theese-plans-are {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.theese-plans-are {
  left: 96px;
  color: var(--text-primary);
}
.note-parent {
  position: absolute;
  top: 296px;
  left: 403px;
  width: 633px;
  height: 48px;
  font-size: var(--font-size-13xl);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}
.pricing1 {
  width: 100%;
  position: relative;
  background-color: #e5fcff;
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  height: 3419px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--static-static-white);
  font-family: var(--font-montserrat);
}
