.join-child1 {
  position: absolute;
  top: 0;
  left: calc(50% - 84.5px);
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 169px;
  height: 61px;
}
.join-now1 {
  position: absolute;
  top: 6px;
  left: calc(50% - 67.5px);
  line-height: 150%;
}
.join-wrapper1,
.join5 {
  position: absolute;
  width: 169px;
  height: 61px;
}
.join5 {
  top: 0;
  left: calc(50% - 84.5px);
}
.join-wrapper1 {
  cursor: pointer;
  text-decoration: none;
  top: 38px;
  left: 1185px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--static-static-white);
  font-family: var(--font-roboto);
}
