.registration-forms-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background: conic-gradient(
    from 180deg at 50% 50%,
    #007581 0deg,
    #5ce1e6 298.8deg
  );
  width: 1030px;
  height: 800px;
}
.forms-rect,
.forms-rect-wrapper {
  position: absolute;
  width: 785px;
  height: 705px;
}
.forms-rect {
  top: 0;
  left: 0;
  backdrop-filter: blur(16px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-300);
}
.forms-rect-wrapper {
  top: 51px;
  left: 198px;
}
.browse-pre-made-templates {
  position: absolute;
  top: 671px;
  left: 395px;
  font-weight: 300;
}
.arrow-head-16 {
  position: absolute;
  top: 667px;
  left: 821px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.or1 {
  position: absolute;
  top: 574px;
  left: 573px;
  font-weight: 300;
}
.registration-child {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25) inset;
  backdrop-filter: blur(20px);
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-600);
  width: 183px;
  height: 183px;
}
.registration-form {
  position: absolute;
  top: 92px;
  left: 21px;
  font-weight: 300;
}
.vector-icon10,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  position: absolute;
  height: 2.19%;
  width: 2.19%;
  top: 26.61%;
  right: 54.1%;
  bottom: 71.2%;
  left: 43.72%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon10,
.vector-icon8,
.vector-icon9 {
  top: 34.59%;
  bottom: 63.22%;
}
.vector-icon10,
.vector-icon9 {
  height: 26.23%;
  width: 26.23%;
  top: 16.39%;
  right: 36.61%;
  bottom: 57.38%;
  left: 37.16%;
}
.vector-icon10 {
  height: 16.01%;
  width: 16.01%;
  top: 29.51%;
  right: 33.72%;
  bottom: 54.48%;
  left: 50.27%;
}
.ai-child,
.registration1 {
  position: absolute;
  top: 0;
  left: 220px;
  width: 183px;
  height: 183px;
}
.ai-child {
  left: 0;
  backdrop-filter: blur(20px);
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-600);
}
.create-with-ai {
  position: absolute;
  top: 97px;
  left: 10px;
  font-weight: 300;
}
.vector-icon11,
.vector-icon12 {
  position: absolute;
  height: 26.07%;
  width: 26.07%;
  top: 19.45%;
  right: 40.22%;
  bottom: 54.48%;
  left: 33.72%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon12 {
  height: 13.66%;
  width: 13.66%;
  top: 13.5%;
  right: 33.17%;
  bottom: 72.84%;
  left: 53.17%;
}
.ai,
.create-child {
  position: absolute;
  top: 0;
  width: 183px;
  height: 183px;
}
.ai {
  left: 450px;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25) inset;
}
.create-child {
  left: 0;
  backdrop-filter: blur(15px);
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-600);
}
.create-from {
  margin: 0;
}
.scratch {
  margin: 0;
  white-space: pre-wrap;
}
.create-from-scratch-container {
  position: absolute;
  top: 97px;
  left: calc(50% - 67.5px);
  font-weight: 300;
  display: inline-block;
  width: 135px;
}
.vector-icon13 {
  position: absolute;
  height: 26.23%;
  width: 26.23%;
  top: 20.77%;
  right: 37.16%;
  bottom: 53.01%;
  left: 36.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.create {
  top: 0;
  left: 0;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25) inset;
  width: 183px;
  height: 183px;
}
.create,
.forms1,
.forms2 {
  position: absolute;
}
.forms1 {
  top: 320px;
  left: 274px;
  width: 633px;
  height: 183px;
}
.forms2 {
  top: 139px;
  left: 497px;
  font-size: var(--font-size-31xl);
  font-weight: 600;
}
.small-rect {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-200);
  width: 112px;
  height: 705px;
}
.vector-icon14,
.vector-icon15 {
  position: absolute;
  height: 71.4%;
  width: 71.4%;
  top: 14.3%;
  right: 14.3%;
  bottom: 14.3%;
  left: 14.3%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon15 {
  height: 43%;
  width: 24.8%;
  top: 28.5%;
  right: 37.6%;
  bottom: 28.5%;
  left: 37.6%;
}
.dollor-1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 378px;
  left: 6px;
  width: 100px;
  height: 100px;
  overflow: auto;
}
.vector-icon16,
.vector-icon17,
.vector-icon18,
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  position: absolute;
  height: 13.5%;
  width: 16.63%;
  top: 72.88%;
  right: 79.25%;
  bottom: 13.63%;
  left: 4.13%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon17,
.vector-icon18,
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  height: 34.38%;
  top: 52.13%;
  right: 54.25%;
  bottom: 13.5%;
  left: 29.13%;
}
.vector-icon18,
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  height: 26%;
  top: 60.38%;
  right: 29.25%;
  bottom: 13.63%;
  left: 54.13%;
}
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  height: 55.25%;
  top: 31.25%;
  right: 4.25%;
  bottom: 13.5%;
  left: 79.13%;
}
.vector-icon20,
.vector-icon21 {
  height: 56.25%;
  width: 91.63%;
  top: 0;
  right: 2.13%;
  bottom: 43.75%;
  left: 6.25%;
}
.vector-icon21 {
  height: 8.38%;
  width: 100%;
  top: 91.63%;
  right: 0;
  bottom: 0;
  left: 0;
}
.analytics2-1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 241px;
  left: 16px;
  width: 80px;
  height: 80px;
  overflow: auto;
}
.vector-icon22 {
  position: absolute;
  height: 75.06%;
  width: 75%;
  top: 12.53%;
  right: 12.5%;
  bottom: 12.41%;
  left: 12.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.plus-2,
.plus-2-wrapper {
  position: absolute;
  width: 80px;
  height: 83px;
}
.plus-2 {
  top: 0;
  left: 0;
  overflow: hidden;
}
.plus-2-wrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 95px;
  left: 16px;
}
.side-nav {
  position: absolute;
  top: 51px;
  left: 40px;
  width: 112px;
  height: 705px;
}
.forms,
.fromsanapay {
  position: absolute;
  top: 0;
  left: 0;
  width: 1030px;
  height: 800px;
}
.fromsanapay {
  top: 4758px;
  left: 178px;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}
