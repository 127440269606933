.form-child2 {
  position: absolute;
  top: 0;
  left: calc(50% - 380.5px);
  backdrop-filter: blur(20px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-600);
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 761px;
  height: 582px;
}
.what-features-of1 {
  position: absolute;
  top: 22px;
  left: 127px;
  line-height: 150%;
}
.form-child3,
.form-child4,
.form-child5,
.form-child6 {
  border: 1px solid var(--color-teal-200);
  outline: 0;
  background-color: transparent;
  position: absolute;
  top: 59px;
  left: 56px;
  border-radius: var(--br-xl);
  box-sizing: border-box;
  width: 620px;
  height: 66px;
}
.form-child4,
.form-child5,
.form-child6 {
  top: 175px;
}
.form-child5,
.form-child6 {
  top: 291px;
}
.form-child6 {
  top: 411px;
}
.how-likely-are {
  position: absolute;
  top: 374px;
  left: 135px;
  line-height: 150%;
}
.are-there-any1,
.is-there-anything {
  position: absolute;
  top: 142px;
  left: 94px;
  line-height: 150%;
}
.are-there-any1 {
  top: 260px;
  left: 126px;
}
.form2 {
  position: absolute;
  top: 403px;
  left: calc(50% - 380px);
  width: 761px;
  height: 582px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}
