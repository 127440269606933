.group-child104,
.testimonials-child7 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--radius-full);
  width: 1000px;
  height: 300px;
}
.testimonials-child7 {
  backdrop-filter: blur(24px);
  border-radius: var(--br-131xl);
  background-color: var(--color-gray-1300);
  border: 2px solid var(--color-teal-100);
  box-sizing: border-box;
}
.synclyfy-has-revolutionized1 {
  position: absolute;
  top: 68px;
  left: 346px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 583px;
}
.founder-xyz4 {
  position: absolute;
  top: 247px;
  left: 709px;
  font-size: var(--font-size-mini);
  line-height: 150%;
  font-weight: 300;
}
.rectangle-parent19 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1000px;
  height: 300px;
}
.d-avatars-11,
.testimonial-swipe-icon5 {
  position: absolute;
  top: 240px;
  left: 470px;
  width: 122px;
  height: 18px;
}
.d-avatars-11 {
  top: 46.2px;
  left: 53px;
  width: 209px;
  height: 209px;
  object-fit: cover;
}
.testimonials7 {
  position: absolute;
  top: 1012px;
  left: calc(50% - 906px);
  width: 1067.6px;
  height: 315.8px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
