.art-swipe-icon5 {
  position: absolute;
  top: 862px;
  left: 237px;
  width: 122px;
  height: 18px;
}
.networking-is-more1 {
  display: inline-block;
  width: 462px;
}
.mr-chat-gpt5,
.networking-is-more1 {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 100;
}
.mr-chat-gpt-wrapper3 {
  position: absolute;
  top: 467px;
  left: 349px;
  width: 133px;
  height: 47px;
  font-size: var(--font-size-lg);
}
.networking-is-more-than-just-e-group {
  position: absolute;
  top: 197px;
  left: 54px;
  width: 482px;
  height: 514px;
}
.start-your-networking1 {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  display: inline-block;
  width: 507px;
}
.start-your-networking-journey-container {
  position: absolute;
  top: 62px;
  left: 34px;
  width: 527px;
  height: 140px;
  font-size: var(--font-size-21xl);
}
.articles-child,
.frame-child33 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  border: 1px solid var(--static-static-white);
  box-sizing: border-box;
  width: 589px;
  height: 915px;
}
.frame-child33 {
  top: 10px;
  left: 10px;
  border-radius: var(--br-11xl);
  border: 1px solid var(--color-gray-300);
  width: 189px;
  height: 80px;
}
.articles-inner {
  position: absolute;
  top: 714px;
  left: 193px;
  width: 209px;
  height: 100px;
}
.learn-more6 {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
}
.learn-more-wrapper4 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 732px;
  left: 208px;
  width: 179px;
  height: 65px;
  font-size: var(--font-size-11xl);
  color: inherit;
}
.vector-icon6 {
  width: 35px;
  position: relative;
  height: 52.5px;
}
.arrow-head-15,
.arrow-head-25 {
  position: absolute;
  top: 836px;
  overflow: hidden;
}
.arrow-head-15 {
  left: 456px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-mid);
}
.arrow-head-25 {
  left: 43px;
  width: 69.2px;
  height: 70.7px;
  object-fit: contain;
}
.articles1 {
  position: absolute;
  top: 185px;
  left: 785px;
  width: 589px;
  height: 915px;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}
