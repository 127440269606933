.success-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing4 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.pricing-frame {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar4 {
  position: absolute;
  top: 10px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-200);
  width: 1376px;
  height: 138px;
  font-size: var(--font-size-13xl);
}
.synclyfy-v1-1-icon3 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.group-a,
.synclyfyv2-1-icon3 {
  position: absolute;
  left: 0;
  height: 200px;
}
.synclyfyv2-1-icon3 {
  top: 0;
  width: 200px;
  overflow: hidden;
  object-fit: cover;
}
.group-a {
  cursor: pointer;
  text-decoration: none;
  top: calc(50% - 533px);
  width: 392px;
}
.success-youve-secured1 {
  position: absolute;
  top: 158px;
  left: calc(50% - 331px);
  line-height: 150%;
  display: inline-block;
  width: 662px;
}
.check-circle-icon1 {
  position: absolute;
  top: 251px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.wed-love-your1 {
  position: absolute;
  top: 362px;
  left: 340px;
  font-size: var(--font-size-xl);
  line-height: 150%;
  display: inline-block;
  width: 831px;
}
.submit-item,
.submit4 {
  position: absolute;
  top: 0;
}
.submit-item {
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-teal-200);
  width: 186px;
  height: 46px;
}
.submit4 {
  left: 43px;
  line-height: 150%;
}
.submit3 {
  text-decoration: none;
  position: absolute;
  top: 921px;
  left: 628px;
  width: 186px;
  height: 46px;
  cursor: pointer;
  color: var(--static-static-white);
}
.success {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
