.instaicon-1,
.linkedinicon-1 {
  position: absolute;
  width: 70px;
  cursor: pointer;
}
.linkedinicon-1 {
  top: 5721.4px;
  left: 1205px;
  height: 70px;
}
.instaicon-1 {
  top: 5727.4px;
  left: 1286px;
  height: 60px;
}
.synclyfy-all-rights {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
}
.trademark {
  top: 5818.4px;
  left: calc(50% - 156.5px);
  width: 339px;
  height: 50px;
}
.layer-2-icon,
.line-icon,
.trademark {
  position: absolute;
}
.line-icon {
  top: 5785.9px;
  left: 25px;
  width: 1460px;
  height: 20px;
}
.layer-2-icon {
  top: 5722.4px;
  left: 1124px;
  width: 70px;
  height: 66px;
  cursor: pointer;
}
.footer-child {
  position: absolute;
  top: calc(50% - 2934.2px);
  left: 0;
  width: 392px;
  height: 5852.4px;
  object-fit: cover;
}
.footer {
  position: absolute;
  top: 28172.6px;
  left: -36px;
  width: 1485px;
  height: 5868.4px;
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
  font-family: var(--font-roboto);
}
.frame-child {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 200px;
  background-color: var(--color-teal-200);
  width: 1219px;
  height: 364px;
}
.before-footer-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 1239px;
  height: 384px;
}
.discover-the-most {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 300;
  display: inline-block;
  width: 818px;
}
.discover-the-most-exciting-tec-wrapper {
  position: absolute;
  top: 92px;
  left: calc(50% - 378.5px);
  width: 740px;
  height: 200px;
}
.button {
  position: absolute;
  top: 22px;
  left: 12px;
  line-height: 150%;
}
.stylesecondary-smallfalse {
  position: absolute;
  top: 15.8px;
  left: -0.2px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.4));
  border-radius: var(--br-11xl);
  border: 0.5px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 165.5px;
  height: 68.5px;
}
.actions,
.loginsignup-3 {
  position: absolute;
  height: 96px;
}
.actions {
  top: 0;
  left: 109px;
  width: 165px;
  cursor: pointer;
}
.loginsignup-3 {
  top: 433px;
  left: 428px;
  width: 192px;
  font-size: var(--font-size-base);
  color: var(--color-teal-200);
  font-family: var(--font-roboto);
}
.before-footer {
  position: absolute;
  top: 33215px;
  left: 90px;
  width: 1239px;
  height: 529px;
  color: var(--static-static-white);
}
.the-founders {
  position: absolute;
  top: 0;
  left: calc(50% - 190px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
}
.team-bg-tile,
.urooba-info-child {
  position: absolute;
  border-radius: var(--br-31xl);
}
.team-bg-tile {
  top: 282px;
  left: calc(50% - 652px);
  width: 1304px;
  height: 452px;
  object-fit: cover;
}
.urooba-info-child {
  top: 0;
  left: 0;
  background-color: var(--color-whitesmoke-200);
  width: 427px;
  height: 149.5px;
}
.co-founder,
.urooba-gohar {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.urooba-gohar {
  top: 12px;
  left: 133px;
  font-weight: 500;
  width: 193px;
  height: 44.8px;
}
.co-founder {
  top: 46.9px;
  left: 160px;
  font-size: var(--font-size-xl);
  font-weight: 100;
  width: 107px;
  height: 29.9px;
}
.urooba-icons,
.urooba-info {
  position: absolute;
  top: 81.7px;
  left: 108px;
  width: 219px;
  height: 49.8px;
}
.urooba-info {
  top: 720.5px;
  left: 58px;
  width: 427px;
  height: 149.5px;
}
.urooba-tile-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 503px;
  height: 646.8px;
  object-fit: cover;
}
.urooba-tile {
  position: absolute;
  top: 189px;
  left: 663px;
  width: 503px;
  height: 870px;
}
.aimal-info-rect-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-whitesmoke-200);
  width: 427px;
  height: 154.1px;
}
.aimal-khan,
.founder-ceo {
  position: absolute;
  top: 12.4px;
  left: 133px;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 160px;
  height: 46.2px;
}
.founder-ceo {
  top: 48.3px;
  left: 138px;
  font-size: var(--font-size-xl);
  font-weight: 100;
  width: 139px;
  height: 30.8px;
}
.aimal-icons {
  position: absolute;
  top: 83.1px;
  left: 108px;
  width: 222px;
  height: 53.4px;
}
.aimal-info-rect,
.aimal-tile-icon {
  position: absolute;
  top: 715.9px;
  left: 38px;
  width: 427px;
  height: 154.1px;
}
.aimal-tile-icon {
  top: 0;
  left: 0;
  width: 503px;
  height: 666.6px;
  object-fit: cover;
}
.aimal-tile {
  position: absolute;
  top: 189px;
  left: 117px;
  width: 503px;
  height: 870px;
}
.founders {
  position: absolute;
  top: 31929px;
  left: calc(50% - 652px);
  width: 1304px;
  height: 1059px;
  font-size: var(--font-size-11xl);
}
.meet-catbot-your {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 600;
}
.meet-catbot-your-purr-fect-te-wrapper {
  position: absolute;
  top: 0;
  left: 64px;
  width: 1270px;
  height: 110px;
}
.introducing-catbot {
  font-weight: 100;
}
.introducing-catbot-hebbonn-container {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  display: inline-block;
  width: 590px;
}
.introducing-catbot-hebbonn-ou-wrapper {
  position: absolute;
  top: 247px;
  left: 69px;
  width: 607px;
  height: 515px;
  font-size: var(--font-size-11xl);
}
.cat-2-icon,
.chat-ui-icon {
  position: absolute;
  object-fit: cover;
}
.cat-2-icon {
  top: 55px;
  left: 588px;
  width: 900px;
  height: 900px;
}
.chat-ui-icon {
  top: 1151px;
  left: calc(50% - 448px);
  width: 896px;
  height: 1475px;
}
.chabot {
  position: absolute;
  top: 25601px;
  left: -48px;
  width: 1488px;
  height: 2725px;
}
.ranks-communities-based {
  font-weight: 300;
}
.leaderboard-inspiring-excelle,
.the-synclyfy-leaderboard-container {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.the-synclyfy-leaderboard-container {
  top: 447px;
  left: 5px;
  width: 714px;
}
.leaderboard-inspiring-excelle {
  top: 0;
  left: 0;
  font-size: var(--font-size-41xl);
  font-weight: 600;
  width: 1244px;
}
.leader-board-icon {
  position: absolute;
  top: 198px;
  left: 1001px;
  border-radius: var(--br-31xl);
  width: 375px;
  height: 812px;
  overflow: hidden;
  object-fit: cover;
}
.leader-board {
  position: absolute;
  top: 24207px;
  left: 29px;
  width: 1376px;
  height: 1010px;
  font-size: var(--font-size-11xl);
}
.join-the-virtual {
  position: absolute;
  top: 10px;
  left: calc(50% - 409.5px);
  line-height: 150%;
  font-weight: 600;
}
.join-the-virtual-communities-wrapper,
.virtaul-community-attendee {
  position: absolute;
  top: 0;
  left: calc(50% - 397.5px);
  width: 795px;
  height: 110px;
}
.virtaul-community-attendee {
  top: 22361px;
  left: calc(50% - 398px);
}
.challenge-accepted-inter-univ {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 1158px;
}
.challenge-accepted-inter-univ-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1178px;
  height: 200px;
}
.uni-hackathons-child,
.uni-hackathons-item {
  position: absolute;
  top: 628px;
  left: 124px;
  width: 516px;
  height: 374px;
  object-fit: cover;
}
.uni-hackathons-item {
  left: 688px;
}
.ready-to-show-container {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  display: inline-block;
  width: 818px;
}
.ready-to-show-off-your-skills-wrapper {
  position: absolute;
  top: 255px;
  left: 372px;
  width: 838px;
  height: 245px;
  font-size: var(--font-size-11xl);
}
.group-child,
.uni-hackathons {
  position: absolute;
}
.uni-hackathons {
  top: 20881px;
  left: 16px;
  width: 1210px;
  height: 1002px;
}
.group-child {
  top: 332.7px;
  left: 0;
  width: 995.7px;
  height: 291.1px;
}
.connect-collaborate,
.engage-with-fellow {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.engage-with-fellow {
  top: 240px;
  left: 30.2px;
  font-weight: 100;
  width: 971.9px;
  height: 160.4px;
}
.connect-collaborate {
  top: 0;
  left: 29.7px;
  font-size: var(--font-size-41xl);
  font-weight: 600;
  width: 862.6px;
  height: 213.9px;
}
.frame-parent {
  position: absolute;
  top: 281.7px;
  left: 0;
  width: 1002.1px;
  height: 623.8px;
}
.chat,
.chat1,
.chats-2-icon {
  position: absolute;
  height: 992px;
}
.chat1,
.chats-2-icon {
  top: 0;
}
.chats-2-icon {
  left: 950px;
  width: 458px;
  overflow: hidden;
  object-fit: cover;
}
.chat,
.chat1 {
  left: 0;
  width: 1408px;
}
.chat {
  top: 19489px;
  font-size: var(--font-size-11xl);
}
.curious-whos-joining,
.get-notified-about-container {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.curious-whos-joining {
  top: 0;
  left: -68px;
  font-weight: 600;
  width: 1163px;
}
.get-notified-about-container {
  top: 473px;
  left: -64px;
  font-size: var(--font-size-11xl);
  width: 559px;
}
.netwrksocail-media-child {
  position: absolute;
  top: 243px;
  left: 586px;
  border-radius: 0 var(--br-81xl) 0 var(--br-81xl);
  width: 725px;
  height: 685.4px;
  object-fit: cover;
}
.shout-out-your {
  position: absolute;
  top: 1064px;
  left: calc(50% - 311.5px);
  font-size: 49px;
  line-height: 150%;
}
.netwrksocail-media {
  position: absolute;
  top: 0;
  left: 64px;
  width: 1217px;
  height: 1483px;
}
.went-to-an-container {
  position: absolute;
  top: 1499px;
  left: 820px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  display: inline-block;
  width: 559px;
}
.fellow-notiff-and-social-media-child {
  position: absolute;
  top: 1269px;
  left: 0;
  border-radius: 0 var(--br-81xl) 0 var(--br-81xl);
  width: 725px;
  height: 685.4px;
  object-fit: cover;
}
.fellow-notiff-and-social-media {
  position: absolute;
  top: 17135px;
  left: 32px;
  width: 1379px;
  height: 1954.4px;
  font-size: var(--font-size-46xl);
}
.if-missed-never {
  position: absolute;
  top: 0;
  left: 125px;
  line-height: 150%;
  font-weight: 600;
}
.stay-in-the-container {
  position: absolute;
  top: 127px;
  left: 190.5px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  text-align: left;
  display: inline-block;
  width: 782px;
  height: 182px;
}
.calendernotif,
.notification-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1163px;
  height: 1035px;
}
.notification-icon {
  top: 338px;
  height: 697px;
  object-fit: cover;
}
.notification {
  position: absolute;
  top: 15651px;
  left: 139px;
  width: 1163px;
  height: 1035px;
  text-align: center;
  font-size: var(--font-size-46xl);
}
.but-just-forgot {
  margin: 0;
}
.but-just-forgot-container {
  position: absolute;
  top: 72px;
  left: 10px;
  font-size: var(--font-size-21xl);
  line-height: 150%;
}
.attended-event {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
  font-weight: 600;
}
.attended-event-icon {
  position: absolute;
  top: -22px;
  left: -43px;
  width: 760px;
  height: 632.1px;
  object-fit: cover;
}
.but-curious-about-container,
.missed-event {
  position: absolute;
  left: 367px;
  line-height: 150%;
}
.missed-event {
  top: 670px;
  font-weight: 600;
}
.but-curious-about-container {
  top: 765px;
  font-size: var(--font-size-21xl);
}
.arrow-down-right-icon {
  position: absolute;
  top: 635.5px;
  left: 684.5px;
  width: 48.9px;
  height: 48.9px;
  overflow: hidden;
  object-fit: contain;
}
.attened-or-miss {
  position: absolute;
  top: 236px;
  left: 345px;
  width: 675px;
  height: 458px;
}
.arrow-down-right-icon1 {
  position: absolute;
  top: 210px;
  left: 225px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.event-materials {
  position: absolute;
  top: 14184px;
  left: 34px;
  width: 1020px;
  height: 694px;
  font-size: var(--font-size-46xl);
}
.explore-get-exact,
.with-our-location {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.explore-get-exact {
  top: 0;
  left: -101px;
  font-weight: 600;
  white-space: pre-wrap;
  width: 1163px;
}
.with-our-location {
  top: 98px;
  left: 270px;
  font-size: var(--font-size-11xl);
  font-weight: 100;
  width: 677px;
}
.explore-det-exact {
  position: absolute;
  top: 0;
  left: 0;
  width: 1163px;
  height: 376px;
}
.explore-img-icon {
  position: absolute;
  top: 376px;
  left: calc(50% - 577px);
  width: 1163px;
  height: 785px;
  object-fit: cover;
}
.transport {
  position: absolute;
  top: 12648px;
  left: 130px;
  width: 1172px;
  height: 1161px;
  font-size: var(--font-size-46xl);
}
.bg-1-icon {
  position: absolute;
  top: 368px;
  left: 0;
  border-radius: var(--br-31xl);
  width: 1313px;
  height: 1078px;
  overflow: hidden;
  object-fit: cover;
}
.fed-up-with,
.weve-got-you-container {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.fed-up-with {
  top: 0;
  left: 38px;
  font-size: var(--font-size-46xl);
  font-weight: 600;
  width: 1075px;
}
.weve-got-you-container {
  top: 10px;
  left: 10px;
  width: 539px;
}
.weve-got-you-covered-enjoy-s-wrapper {
  position: absolute;
  top: 88px;
  left: 698px;
  width: 559px;
  height: 155px;
}
.registeration-form1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
  font-weight: 100;
}
.reg-from-logo {
  position: absolute;
  top: 74px;
  left: 177px;
  width: 272px;
  height: 48px;
  color: var(--text-primary);
}
.name-child {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--color-teal-100);
  width: 450px;
  height: 58px;
}
.email,
.name {
  left: 40px;
  width: 450px;
  height: 58px;
}
.name {
  position: absolute;
  top: 239px;
}
.email {
  top: 366px;
}
.address,
.email,
.phone {
  position: absolute;
}
.phone {
  top: 493px;
  left: 40px;
  width: 450px;
  height: 58px;
}
.address {
  top: 5px;
  left: 45px;
  line-height: 150%;
  font-weight: 100;
}
.adress {
  position: absolute;
  top: 620px;
  left: 40px;
  width: 450px;
  height: 58px;
}
.registr-submit-child {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--color-teal-100);
  width: 333px;
  height: 50px;
}
.submit {
  position: absolute;
  top: 1px;
  left: 113px;
  line-height: 150%;
  font-weight: 100;
}
.registr-submit {
  position: absolute;
  top: 747px;
  left: 99px;
  width: 333px;
  height: 50px;
}
.synclyfybg-1-icon {
  position: absolute;
  top: 58px;
  left: 74px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.payments-bg-rectangle,
.registeration-form {
  position: absolute;
  backdrop-filter: blur(26px);
  border-radius: var(--br-31xl);
  background-color: var(--color-rosybrown-100);
  width: 530px;
  height: 907px;
}
.registeration-form {
  top: 453px;
  left: calc(50% - 587.5px);
  box-shadow: 16px 16px 11px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-13xl);
  color: var(--static-static-white);
}
.payments-bg-rectangle {
  top: 0;
  left: 0;
  box-shadow: 9px 11px 4px rgba(0, 0, 0, 0.25);
}
.secure-payments {
  position: absolute;
  top: 75px;
  left: 204px;
  width: 260px;
  height: 48px;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
}
.layer-1-icon {
  position: absolute;
  height: 12.96%;
  width: 93.74%;
  top: 44.32%;
  right: 2.81%;
  bottom: 42.72%;
  left: 3.45%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.or {
  position: absolute;
  top: 123px;
  left: 213px;
  line-height: 150%;
  font-weight: 100;
}
.payment-gateway-logo-vec-16772 {
  position: absolute;
  top: 314px;
  left: 49px;
  width: 470px;
  height: 470px;
  overflow: hidden;
}
.easypaisa-child {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--static-static-white);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 451px;
  height: 59px;
}
.pay-with {
  position: absolute;
  top: 5px;
  left: 62px;
  line-height: 150%;
  font-weight: 100;
}
.b39f879357-easypaisa-1-icon {
  position: absolute;
  top: 8px;
  left: 293px;
  width: 93px;
  height: 42px;
  object-fit: cover;
}
.easypaisa {
  position: absolute;
  top: 330px;
  left: 70px;
  width: 450px;
  height: 58px;
  color: var(--color-teal-100);
}
.jazz-cah-child {
  position: absolute;
  top: 19.5px;
  left: -0.5px;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--static-static-white);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 451px;
  height: 59px;
}
.pay-with1 {
  position: absolute;
  top: 25px;
  left: 62px;
  line-height: 150%;
  font-weight: 100;
}
.jazzcash-icon {
  position: absolute;
  top: 0;
  left: 315.4px;
  width: 110.4px;
  height: 110.4px;
  object-fit: contain;
}
.jazz-cah {
  top: 220px;
  left: 70px;
  width: 450px;
  height: 110.4px;
  color: var(--color-teal-100);
}
.jazz-cah,
.next,
.pay-next {
  position: absolute;
}
.next {
  top: 1px;
  left: 132px;
  line-height: 150%;
  font-weight: 100;
}
.pay-next {
  top: 738px;
  left: 127px;
  width: 333px;
  height: 50px;
  font-size: var(--font-size-13xl);
}
.synclyfybg-1-icon1 {
  position: absolute;
  top: 59px;
  left: 104px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.payment-form {
  position: absolute;
  top: 462px;
  left: 682px;
  width: 530px;
  height: 1032px;
  color: var(--static-static-white);
}
.registrationpayments {
  position: absolute;
  top: 12166px;
  left: 64px;
  width: 1313px;
  height: 1494px;
  display: none;
}
.vector-icon {
  position: absolute;
  top: 559px;
  left: 642px;
  width: 93px;
  height: 83px;
}
.community-pfp-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-11xl);
  background: linear-gradient(
    180deg,
    rgba(220, 220, 220, 0.56) 89.5%,
    rgba(118, 118, 118, 0.31)
  );
  width: 652.2px;
  height: 604.9px;
}
.speaker-profile,
.with-synclyfys-speaker {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.with-synclyfys-speaker {
  top: 278.8px;
  left: 64.1px;
  font-weight: 100;
  width: 551.2px;
  height: 198.8px;
}
.speaker-profile {
  top: 157.8px;
  left: calc(50% - 187.3px);
  font-size: var(--font-size-31xl);
  font-weight: 500;
  text-align: left;
  width: 373.5px;
  height: 78.9px;
}
.community-pfp {
  position: absolute;
  top: 298px;
  left: 723.8px;
  width: 652.2px;
  height: 604.9px;
  text-align: center;
}
.create-a-personalized,
.user-profile1 {
  position: absolute;
  line-height: 150%;
}
.user-profile1 {
  top: 148px;
  left: 174px;
  font-weight: 500;
}
.create-a-personalized {
  top: 236px;
  left: 48px;
  font-size: var(--font-size-6xl);
  font-weight: 100;
  text-align: center;
  display: inline-block;
  width: 524px;
  height: 189px;
}
.personalpfp-1,
.user-pf-logo {
  position: absolute;
}
.user-pf-logo {
  top: 61px;
  left: 283.4px;
  width: 69.6px;
  height: 83.8px;
}
.personalpfp-1 {
  top: -6.3px;
  left: 274.6px;
  width: 89.4px;
  height: 89.4px;
  overflow: hidden;
  display: none;
}
.user-profile {
  position: absolute;
  top: 298px;
  left: 0;
  width: 652.2px;
  height: 604.9px;
  font-size: var(--font-size-31xl);
}
.user-pf-logo1 {
  position: absolute;
  top: 348px;
  left: 965px;
  width: 66.1px;
  height: 79.7px;
}
.button1 {
  position: absolute;
  top: 22px;
  left: 60.5px;
  line-height: 150%;
}
.stylesecondary-smallfalse1 {
  position: absolute;
  top: 15.8px;
  left: -0.2px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.4));
  border-radius: var(--br-11xl);
  border: 0.5px solid var(--text-primary);
  box-sizing: border-box;
  width: 165.5px;
  height: 68.5px;
}
.actions1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 165px;
  height: 96px;
}
.button2 {
  position: absolute;
  top: 22px;
  left: 55px;
  line-height: 150%;
}
.stylesecondary-smallfalse2 {
  position: absolute;
  top: 15.8px;
  left: -0.2px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.4));
  border-radius: var(--br-11xl);
  border: 0.5px solid var(--color-teal-100);
  box-sizing: border-box;
  width: 165.5px;
  height: 68.5px;
}
.actions2,
.loginsignup-2 {
  position: absolute;
  height: 96px;
}
.actions2 {
  top: 0;
  left: 192px;
  width: 165px;
  color: var(--color-teal-100);
}
.loginsignup-2 {
  top: 940px;
  left: 509px;
  width: 357px;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
}
.the-profiles-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-teal-100);
  width: 422px;
  height: 143px;
}
.the-profiles2 {
  position: absolute;
  top: 23px;
  left: 28px;
  line-height: 150%;
  font-weight: 600;
}
.the-profiles-wrapper,
.the-profiles1 {
  position: absolute;
  top: 0;
  width: 422px;
  height: 143px;
}
.the-profiles1 {
  left: 0;
  box-shadow: 0 15px 13px #ccc;
}
.the-profiles-wrapper {
  left: 477px;
  font-size: var(--font-size-46xl);
  color: var(--static-static-white);
}
.the-profiles {
  position: absolute;
  top: 4127px;
  left: 32px;
  width: 1376px;
  height: 1036px;
  display: none;
  font-size: var(--font-size-6xl);
}
.events-feed-child {
  position: absolute;
  top: 188px;
  left: 0;
  border-radius: var(--br-31xl);
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(11, 199, 199, 0.5) -40.92deg,
    rgba(20, 116, 105, 0.5) 61.8deg,
    rgba(82, 164, 70, 0.5) 189deg,
    rgba(11, 199, 199, 0.5) 319.08deg,
    rgba(20, 116, 105, 0.5) 421.8deg
  );
  width: 1376px;
  height: 909px;
}
.events-feed-inner {
  position: absolute;
  top: 269px;
  object-fit: cover;
}
.events-feed-item {
  top: 269px;
  left: 42px;
  border-radius: var(--br-31xl) 195px 0 var(--br-31xl);
  width: 695px;
  height: 746px;
}
.events-feed-item,
.rectangle-icon {
  position: absolute;
  object-fit: cover;
}
.events-feed-inner,
.rectangle-icon {
  left: 833px;
  border-radius: 0 59px 0 var(--br-131xl);
  width: 500px;
  height: 340px;
}
.rectangle-icon {
  top: 675px;
  border-radius: var(--br-131xl) 0 var(--br-31xl) 0;
}
.discover-events-tailored-container {
  position: absolute;
  top: 98px;
  left: 178.5px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  font-weight: 100;
  text-align: left;
}
.personilized {
  position: absolute;
  top: 0;
  left: 299px;
  width: 778px;
  height: 188px;
}
.events-feed,
.hidden-pre {
  position: absolute;
  display: none;
}
.events-feed {
  top: 2582px;
  left: 32px;
  width: 1376px;
  height: 1097px;
  text-align: center;
  font-size: var(--font-size-46xl);
}
.hidden-pre {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  font-size: var(--font-size-11xl);
}
.streamline-check-ins-with {
  position: absolute;
  top: 0;
  left: calc(50% - 546px);
  line-height: 150%;
  font-weight: 600;
}
.say-goodbye-to-container {
  position: absolute;
  top: 458px;
  left: 0;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  display: inline-block;
  width: 668px;
}
.d-character-emerging-from-smar-icon {
  position: absolute;
  top: 90px;
  left: 778px;
  width: 640px;
  height: 961px;
  object-fit: cover;
}
.check-ins {
  position: absolute;
  top: 9551px;
  left: calc(50% - 687px);
  width: 1418px;
  height: 1051px;
}
.explore-connect-and {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 818px;
}
.explore-connect-and-grow-wit-wrapper {
  position: absolute;
  top: 163px;
  left: calc(50% - 419px);
  width: 838px;
  height: 245px;
}
.virtaul-events-child {
  position: absolute;
  top: 0;
  left: 330px;
  width: 795px;
  height: 110px;
}
.virtual-events-icon {
  position: absolute;
  top: 581px;
  left: 0;
  border-radius: 16px;
  width: 1440px;
  height: 900px;
  overflow: hidden;
  object-fit: cover;
}
.unlock-the-power {
  position: absolute;
  top: 46px;
  left: 233px;
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
  text-align: left;
}
.virtaul-events {
  position: absolute;
  top: 7623px;
  left: calc(50% - 720px);
  width: 1440px;
  height: 1481px;
  text-align: center;
  font-size: var(--font-size-11xl);
}
.effortless-registration-payme {
  position: absolute;
  top: 0;
  left: calc(50% - 623px);
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 1246px;
}
.streamline-event-management-container {
  position: absolute;
  top: 90px;
  left: calc(50% - 173px);
  font-size: var(--font-size-11xl);
  line-height: 150%;
  display: inline-block;
  width: 724px;
}
.registration {
  position: absolute;
  top: 4325px;
  left: calc(50% - 690px);
  width: 1246px;
  height: 270px;
}
.boost-your-events {
  position: absolute;
  top: 90px;
  left: 2px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 806px;
}
.promote-and-sponsor-your-event-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 952px;
  height: 225px;
}
.sponsor-event-icon {
  position: absolute;
  top: 410px;
  left: 70px;
  width: 1240px;
  height: 880px;
  object-fit: cover;
}
.search-2-icon {
  position: absolute;
  top: 490px;
  left: 1022px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.ads {
  position: absolute;
  top: 5882px;
  left: 30px;
  width: 1310px;
  height: 1290px;
}
.home-child {
  position: absolute;
  top: 1122px;
  left: calc(50% - 770px);
  border-radius: var(--br-31xl);
  width: 1458px;
  height: 1240px;
}
.synclyfy-curates-events-container {
  position: absolute;
  top: 91px;
  left: 0;
  line-height: 150%;
  display: inline-block;
  width: 725px;
}
.your-personalized-feed {
  position: absolute;
  top: 0;
  left: calc(50% - 362.5px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
}
.description {
  position: absolute;
  top: 2667px;
  left: 32px;
  width: 725px;
  height: 361px;
  font-size: var(--font-size-11xl);
}
.explore-exciting-features-container {
  position: absolute;
  top: 75px;
  left: 0;
  font-size: var(--font-size-46xl);
  line-height: 150%;
  font-weight: 600;
}
.synclyfy-offers-a {
  position: absolute;
  top: 179px;
  left: 437px;
  font-size: 22px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 545px;
  height: 165px;
}
.discover {
  position: absolute;
  top: 2017px;
  left: 28px;
  width: 982px;
  height: 344px;
  font-size: var(--font-size-31xl);
}
.explore-tech-events {
  position: absolute;
  top: 0;
  left: calc(50% - 495.5px);
  line-height: 150%;
  font-weight: 600;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.discover-conferences-workshop {
  position: absolute;
  top: 63px;
  left: calc(50% - 431.5px);
  font-size: var(--font-size-21xl);
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 927px;
}
.after-hero {
  position: absolute;
  top: 1361px;
  left: calc(50% - 438px);
  width: 991px;
  height: 183px;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-400);
}
.home-item {
  position: absolute;
  top: 236px;
  left: 430px;
  border-radius: var(--br-31xl);
  width: 843.2px;
  height: 735.9px;
  object-fit: contain;
}
.loginsignup-1 {
  position: absolute;
  top: 711px;
  left: 853px;
  width: 357px;
  height: 96px;
  display: none;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
}
.stylesecondary-smallfalse5 {
  position: absolute;
  top: calc(50% - 34px);
  left: calc(50% - 82.5px);
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 165px;
  height: 68px;
}
.actions-wrapper {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 619px;
  left: 948px;
  width: 165px;
  height: 96px;
  font-size: var(--font-size-base);
  color: var(--static-static-white);
  font-family: var(--font-roboto);
}
.hero-para-child {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: var(--blur-glass);
  border-radius: var(--br-xl);
  background-color: rgba(20, 116, 105, 0.15);
  width: 754px;
  height: 304px;
}
.unite-with-tech-container {
  position: absolute;
  top: 21.3px;
  left: 26px;
  line-height: 150%;
  display: inline-block;
  width: 713px;
  height: 255px;
}
.hero-para {
  position: absolute;
  top: 271px;
  left: 654px;
  width: 754px;
  height: 304px;
  font-size: var(--font-size-13xl);
  color: var(--color-teal-200);
  font-family: var(--font-ruda);
}
.master-the-art-of-networking-wrapper {
  position: absolute;
  top: 0;
  left: 311px;
  width: 845px;
  height: 110px;
  font-size: var(--font-size-41xl);
  color: var(--text-primary);
}
.group-parent {
  position: absolute;
  top: 203px;
  left: 0;
  width: 1468px;
  height: 1285px;
}
.used-our-notifications {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 442px;
}
.frame-item {
  position: absolute;
}
.used-our-notifications-and-soc-wrapper {
  position: absolute;
  top: 683px;
  left: 70px;
  width: 462px;
  height: 290px;
}
.frame-item {
  top: 10px;
  left: 10px;
  border-radius: var(--br-11xl);
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  width: 189px;
  height: 80px;
}
.netwrking-inner {
  position: absolute;
  top: 1021px;
  left: 161px;
  width: 209px;
  height: 100px;
}
.learn-more-wrapper {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 1039px;
  left: 176px;
  width: 179px;
  height: 65px;
  color: inherit;
}
.netwrking {
  top: 28555px;
  left: -17px;
  width: 1468px;
  height: 1488px;
  font-size: var(--font-size-11xl);
  color: var(--static-static-white);
}
.frame-inner,
.netwrking {
  position: absolute;
}
.testimonials-child {
  position: absolute;
  top: 561px;
  left: 0;
  width: 1815px;
  height: 1525px;
}
.frame-inner {
  top: 122.1px;
  left: 29.5px;
  border-radius: var(--br-11xl);
  width: 1107px;
  height: 1276.5px;
  object-fit: contain;
}
.testimonials,
.vector-parent {
  position: absolute;
}
.vector-parent {
  top: 0;
  left: 858px;
  width: 1596px;
  height: 1572.9px;
}
.testimonials {
  top: 29877px;
  left: -496px;
  width: 2454px;
  height: 2086px;
}
.design-marketplace,
.discover-a-wide {
  position: absolute;
  top: 0;
  left: 344px;
  line-height: 150%;
  font-weight: 600;
}
.discover-a-wide {
  top: 140px;
  left: 185.5px;
  font-size: var(--font-size-11xl);
  font-weight: 100;
  display: inline-block;
  width: 869px;
}
.design-marketplace-icon {
  position: absolute;
  top: 325px;
  left: calc(50% - 620px);
  width: 1240px;
  height: 880px;
  object-fit: cover;
}
.search-1-icon {
  position: absolute;
  top: 385px;
  left: 871px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.design {
  position: absolute;
  top: 10983px;
  left: 89px;
  width: 1240px;
  height: 1205px;
}
.pricing {
  position: absolute;
  top: 44px;
  left: 1005px;
  line-height: 150%;
  cursor: pointer;
}
.nav-bar1 {
  position: absolute;
  top: 31px;
  left: calc(50% - 666px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
}
.logo-child,
.logo-item {
  position: absolute;
  height: 74.42%;
  width: 78%;
  top: 22.72%;
  right: 0;
  bottom: 2.86%;
  left: 22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.logo-item {
  height: 100%;
  width: 19.18%;
  top: 0;
  right: 80.82%;
  bottom: 0;
  left: 0;
  object-fit: cover;
}
.logo {
  height: 27.95%;
  width: 22.76%;
  top: 36.05%;
  right: 72.37%;
  bottom: 36%;
  left: 4.87%;
}
.home1,
.logo,
.nav-bar {
  position: absolute;
}
.nav-bar {
  top: -20px;
  left: -12px;
  width: 1420px;
  height: 200px;
  font-size: var(--font-size-13xl);
}
.home1 {
  top: 0;
  left: 0;
  background-color: #d7e4fa;
  width: 1440px;
  height: 34485px;
}
.home,
.home-wrapper {
  background-color: var(--color-lavender-100);
}
.home-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 34937px;
}
.home {
  width: 100%;
  position: relative;
  height: 34133px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
