.org-success-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing7 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.frame-div {
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  cursor: pointer;
}
.nav-bar8 {
  position: absolute;
  top: 10px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-200);
  width: 1376px;
  height: 138px;
}
.org-success-inner {
  position: absolute;
  top: calc(50% - 533px);
  left: 0;
  width: 392px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}
.thank-you-for {
  position: absolute;
  top: calc(50% - 103.5px);
  left: calc(50% - 331px);
  line-height: 150%;
  display: inline-block;
  width: 662px;
}
.check-circle-icon2 {
  position: absolute;
  top: calc(50% + 3.5px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.thank-you-for-your-feedback-y-parent {
  position: absolute;
  top: calc(50% - 104px);
  left: calc(50% - 331px);
  width: 662px;
  height: 207px;
  font-size: var(--font-size-11xl);
}
.org-success1 {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
