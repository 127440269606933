.bgblack-1-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 1442px;
  height: 1645px;
  object-fit: cover;
}
.bgblack-1-wrapper {
  position: absolute;
  top: 1060px;
  left: 0;
  width: 1456px;
  height: 1665px;
}
