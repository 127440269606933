.attendee-feed-icon {
  position: absolute;
  top: 93px;
  left: 0;
  width: 1241px;
  height: 880px;
  object-fit: cover;
}
.frame-child34 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-teal-200);
}
.attnorg-ui-btn-inner7,
.frame-child34,
.frame-child35 {
  position: absolute;
  left: 0;
  width: 150px;
  height: 30px;
}
.attnorg-ui-btn-inner7 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 1px;
}
.frame-child35 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--static-static-white);
}
.organizer-feed2 {
  position: absolute;
  top: 3px;
  left: 22px;
  font-size: var(--font-size-mini);
  line-height: 150%;
  font-weight: 100;
  font-family: var(--font-saira);
  color: var(--color-teal-200);
  text-align: left;
}
.rectangle-parent20 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 162px;
  width: 150px;
  height: 30px;
  overflow-y: auto;
}
.attendee-feed2 {
  position: absolute;
  top: 4px;
  left: 24px;
  line-height: 150%;
  font-weight: 100;
}
.attnorg-ui-btn9,
.feed-ui1 {
  position: absolute;
  top: 0;
  left: 465px;
  width: 312px;
  height: 31px;
}
.feed-ui1 {
  top: 3088px;
  left: 89px;
  width: 1241px;
  height: 958px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}
