@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ruda:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=SF+Pro:wght@400&display=swap);
.bgblack-1-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 1442px;
  height: 1645px;
  object-fit: cover;
}
.bgblack-1-wrapper {
  position: absolute;
  top: 1060px;
  left: 0;
  width: 1456px;
  height: 1665px;
}

.group-child103,
.rectangle-wrapper1 {
  position: absolute;
  width: 1448px;
  height: 1265px;
}
.group-child103 {
  top: 0;
  left: 0;
  background-color: var(--text-primary);
}
.rectangle-wrapper1 {
  top: 10px;
  left: 10px;
}

.art-swipe-icon5 {
  position: absolute;
  top: 862px;
  left: 237px;
  width: 122px;
  height: 18px;
}
.networking-is-more1 {
  display: inline-block;
  width: 462px;
}
.mr-chat-gpt5,
.networking-is-more1 {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 100;
}
.mr-chat-gpt-wrapper3 {
  position: absolute;
  top: 467px;
  left: 349px;
  width: 133px;
  height: 47px;
  font-size: var(--font-size-lg);
}
.networking-is-more-than-just-e-group {
  position: absolute;
  top: 197px;
  left: 54px;
  width: 482px;
  height: 514px;
}
.start-your-networking1 {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  display: inline-block;
  width: 507px;
}
.start-your-networking-journey-container {
  position: absolute;
  top: 62px;
  left: 34px;
  width: 527px;
  height: 140px;
  font-size: var(--font-size-21xl);
}
.articles-child,
.frame-child33 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  border: 1px solid var(--static-static-white);
  box-sizing: border-box;
  width: 589px;
  height: 915px;
}
.frame-child33 {
  top: 10px;
  left: 10px;
  border-radius: var(--br-11xl);
  border: 1px solid var(--color-gray-300);
  width: 189px;
  height: 80px;
}
.articles-inner {
  position: absolute;
  top: 714px;
  left: 193px;
  width: 209px;
  height: 100px;
}
.learn-more6 {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
}
.learn-more-wrapper4 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 732px;
  left: 208px;
  width: 179px;
  height: 65px;
  font-size: var(--font-size-11xl);
  color: inherit;
}
.vector-icon6 {
  width: 35px;
  position: relative;
  height: 52.5px;
}
.arrow-head-15,
.arrow-head-25 {
  position: absolute;
  top: 836px;
  overflow: hidden;
}
.arrow-head-15 {
  left: 456px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-mid);
}
.arrow-head-25 {
  left: 43px;
  width: 69.2px;
  height: 70.7px;
  object-fit: contain;
}
.articles1 {
  position: absolute;
  top: 185px;
  left: 785px;
  width: 589px;
  height: 915px;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}

.group-child104,
.testimonials-child7 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--radius-full);
  width: 1000px;
  height: 300px;
}
.testimonials-child7 {
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
  border-radius: var(--br-131xl);
  background-color: var(--color-gray-1300);
  border: 2px solid var(--color-teal-100);
  box-sizing: border-box;
}
.synclyfy-has-revolutionized1 {
  position: absolute;
  top: 68px;
  left: 346px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 583px;
}
.founder-xyz4 {
  position: absolute;
  top: 247px;
  left: 709px;
  font-size: var(--font-size-mini);
  line-height: 150%;
  font-weight: 300;
}
.rectangle-parent19 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1000px;
  height: 300px;
}
.d-avatars-11,
.testimonial-swipe-icon5 {
  position: absolute;
  top: 240px;
  left: 470px;
  width: 122px;
  height: 18px;
}
.d-avatars-11 {
  top: 46.2px;
  left: 53px;
  width: 209px;
  height: 209px;
  object-fit: cover;
}
.testimonials7 {
  position: absolute;
  top: 1012px;
  left: calc(50% - 906px);
  width: 1067.6px;
  height: 315.8px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.attendee-feed-icon {
  position: absolute;
  top: 93px;
  left: 0;
  width: 1241px;
  height: 880px;
  object-fit: cover;
}
.frame-child34 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-teal-200);
}
.attnorg-ui-btn-inner7,
.frame-child34,
.frame-child35 {
  position: absolute;
  left: 0;
  width: 150px;
  height: 30px;
}
.attnorg-ui-btn-inner7 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 1px;
}
.frame-child35 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--static-static-white);
}
.organizer-feed2 {
  position: absolute;
  top: 3px;
  left: 22px;
  font-size: var(--font-size-mini);
  line-height: 150%;
  font-weight: 100;
  font-family: var(--font-saira);
  color: var(--color-teal-200);
  text-align: left;
}
.rectangle-parent20 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 162px;
  width: 150px;
  height: 30px;
  overflow-y: auto;
}
.attendee-feed2 {
  position: absolute;
  top: 4px;
  left: 24px;
  line-height: 150%;
  font-weight: 100;
}
.attnorg-ui-btn9,
.feed-ui1 {
  position: absolute;
  top: 0;
  left: 465px;
  width: 312px;
  height: 31px;
}
.feed-ui1 {
  top: 3088px;
  left: 89px;
  width: 1241px;
  height: 958px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}

.registration-forms-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background: conic-gradient(
    from 180deg at 50% 50%,
    #007581 0deg,
    #5ce1e6 298.8deg
  );
  width: 1030px;
  height: 800px;
}
.forms-rect,
.forms-rect-wrapper {
  position: absolute;
  width: 785px;
  height: 705px;
}
.forms-rect {
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-300);
}
.forms-rect-wrapper {
  top: 51px;
  left: 198px;
}
.browse-pre-made-templates {
  position: absolute;
  top: 671px;
  left: 395px;
  font-weight: 300;
}
.arrow-head-16 {
  position: absolute;
  top: 667px;
  left: 821px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.or1 {
  position: absolute;
  top: 574px;
  left: 573px;
  font-weight: 300;
}
.registration-child {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25) inset;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-600);
  width: 183px;
  height: 183px;
}
.registration-form {
  position: absolute;
  top: 92px;
  left: 21px;
  font-weight: 300;
}
.vector-icon10,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  position: absolute;
  height: 2.19%;
  width: 2.19%;
  top: 26.61%;
  right: 54.1%;
  bottom: 71.2%;
  left: 43.72%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon10,
.vector-icon8,
.vector-icon9 {
  top: 34.59%;
  bottom: 63.22%;
}
.vector-icon10,
.vector-icon9 {
  height: 26.23%;
  width: 26.23%;
  top: 16.39%;
  right: 36.61%;
  bottom: 57.38%;
  left: 37.16%;
}
.vector-icon10 {
  height: 16.01%;
  width: 16.01%;
  top: 29.51%;
  right: 33.72%;
  bottom: 54.48%;
  left: 50.27%;
}
.ai-child,
.registration1 {
  position: absolute;
  top: 0;
  left: 220px;
  width: 183px;
  height: 183px;
}
.ai-child {
  left: 0;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-600);
}
.create-with-ai {
  position: absolute;
  top: 97px;
  left: 10px;
  font-weight: 300;
}
.vector-icon11,
.vector-icon12 {
  position: absolute;
  height: 26.07%;
  width: 26.07%;
  top: 19.45%;
  right: 40.22%;
  bottom: 54.48%;
  left: 33.72%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon12 {
  height: 13.66%;
  width: 13.66%;
  top: 13.5%;
  right: 33.17%;
  bottom: 72.84%;
  left: 53.17%;
}
.ai,
.create-child {
  position: absolute;
  top: 0;
  width: 183px;
  height: 183px;
}
.ai {
  left: 450px;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25) inset;
}
.create-child {
  left: 0;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-600);
}
.create-from {
  margin: 0;
}
.scratch {
  margin: 0;
  white-space: pre-wrap;
}
.create-from-scratch-container {
  position: absolute;
  top: 97px;
  left: calc(50% - 67.5px);
  font-weight: 300;
  display: inline-block;
  width: 135px;
}
.vector-icon13 {
  position: absolute;
  height: 26.23%;
  width: 26.23%;
  top: 20.77%;
  right: 37.16%;
  bottom: 53.01%;
  left: 36.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.create {
  top: 0;
  left: 0;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25) inset;
  width: 183px;
  height: 183px;
}
.create,
.forms1,
.forms2 {
  position: absolute;
}
.forms1 {
  top: 320px;
  left: 274px;
  width: 633px;
  height: 183px;
}
.forms2 {
  top: 139px;
  left: 497px;
  font-size: var(--font-size-31xl);
  font-weight: 600;
}
.small-rect {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-200);
  width: 112px;
  height: 705px;
}
.vector-icon14,
.vector-icon15 {
  position: absolute;
  height: 71.4%;
  width: 71.4%;
  top: 14.3%;
  right: 14.3%;
  bottom: 14.3%;
  left: 14.3%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon15 {
  height: 43%;
  width: 24.8%;
  top: 28.5%;
  right: 37.6%;
  bottom: 28.5%;
  left: 37.6%;
}
.dollor-1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 378px;
  left: 6px;
  width: 100px;
  height: 100px;
  overflow: auto;
}
.vector-icon16,
.vector-icon17,
.vector-icon18,
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  position: absolute;
  height: 13.5%;
  width: 16.63%;
  top: 72.88%;
  right: 79.25%;
  bottom: 13.63%;
  left: 4.13%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon17,
.vector-icon18,
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  height: 34.38%;
  top: 52.13%;
  right: 54.25%;
  bottom: 13.5%;
  left: 29.13%;
}
.vector-icon18,
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  height: 26%;
  top: 60.38%;
  right: 29.25%;
  bottom: 13.63%;
  left: 54.13%;
}
.vector-icon19,
.vector-icon20,
.vector-icon21 {
  height: 55.25%;
  top: 31.25%;
  right: 4.25%;
  bottom: 13.5%;
  left: 79.13%;
}
.vector-icon20,
.vector-icon21 {
  height: 56.25%;
  width: 91.63%;
  top: 0;
  right: 2.13%;
  bottom: 43.75%;
  left: 6.25%;
}
.vector-icon21 {
  height: 8.38%;
  width: 100%;
  top: 91.63%;
  right: 0;
  bottom: 0;
  left: 0;
}
.analytics2-1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 241px;
  left: 16px;
  width: 80px;
  height: 80px;
  overflow: auto;
}
.vector-icon22 {
  position: absolute;
  height: 75.06%;
  width: 75%;
  top: 12.53%;
  right: 12.5%;
  bottom: 12.41%;
  left: 12.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.plus-2,
.plus-2-wrapper {
  position: absolute;
  width: 80px;
  height: 83px;
}
.plus-2 {
  top: 0;
  left: 0;
  overflow: hidden;
}
.plus-2-wrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 95px;
  left: 16px;
}
.side-nav {
  position: absolute;
  top: 51px;
  left: 40px;
  width: 112px;
  height: 705px;
}
.forms,
.fromsanapay {
  position: absolute;
  top: 0;
  left: 0;
  width: 1030px;
  height: 800px;
}
.fromsanapay {
  top: 4758px;
  left: 178px;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}

.shapes-1-icon6,
.uni-commun-child6 {
  position: absolute;
  top: 0;
  left: 470px;
  width: 912.6px;
  height: 1166.1px;
  object-fit: contain;
}
.uni-commun-child6 {
  left: 473.4px;
  object-fit: cover;
}
.uni-commun-child7,
.uni-commun-child8,
.uni-commun-child9 {
  position: absolute;
  top: 244.4px;
  left: 656.2px;
  border-radius: var(--br-11xl);
  background-color: var(--color-darkslateblue-200);
  width: 685.3px;
  height: 94.8px;
}
.uni-commun-child8,
.uni-commun-child9 {
  top: 359.8px;
  background-color: var(--color-steelblue);
  width: 431.7px;
}
.uni-commun-child9 {
  top: 474px;
  background-color: var(--color-cadetblue-200);
  width: 569.9px;
}
.uni-commun-child10,
.uni-commun-child11,
.uni-commun-child12,
.uni-commun-child13 {
  position: absolute;
  top: 589.3px;
  left: 656.2px;
  border-radius: var(--br-11xl);
  background-color: var(--color-darkslateblue-100);
  width: 657.9px;
  height: 94.8px;
}
.uni-commun-child11,
.uni-commun-child12,
.uni-commun-child13 {
  top: 704.7px;
  background-color: var(--color-brown);
  width: 569.9px;
}
.uni-commun-child12,
.uni-commun-child13 {
  top: 820.1px;
  background-color: var(--color-gray-1100);
  width: 323.2px;
}
.uni-commun-child13 {
  top: 935.4px;
  background-color: var(--color-cornflowerblue);
  width: 185px;
}
.unsplashieebwgy-6la-icon46,
.unsplashieebwgy-6la-icon47,
.unsplashieebwgy-6la-icon48 {
  position: absolute;
  top: 238.7px;
  left: 546.5px;
  border-radius: var(--br-27xl);
  width: 87.9px;
  height: 86.8px;
  object-fit: cover;
}
.unsplashieebwgy-6la-icon47,
.unsplashieebwgy-6la-icon48 {
  top: 704.7px;
  height: 87.9px;
}
.unsplashieebwgy-6la-icon48 {
  top: 820.1px;
}
.unsplashieebwgy-6la-icon49,
.unsplashieebwgy-6la-icon50,
.unsplashieebwgy-6la-icon51,
.unsplashieebwgy-6la-icon52 {
  position: absolute;
  top: 356.3px;
  left: 546.5px;
  border-radius: var(--br-27xl);
  width: 87.9px;
  height: 87.9px;
  object-fit: cover;
}
.unsplashieebwgy-6la-icon50,
.unsplashieebwgy-6la-icon51,
.unsplashieebwgy-6la-icon52 {
  top: 935.4px;
  height: 86.8px;
}
.unsplashieebwgy-6la-icon51,
.unsplashieebwgy-6la-icon52 {
  top: 470.6px;
}
.unsplashieebwgy-6la-icon52 {
  top: 597.3px;
}
.check-out-bootstrap1,
.count-me-in1,
.hey-any-recommendations1,
.im-in-for1,
.im-into-front-end1 {
  position: absolute;
  top: 273px;
  left: 683.6px;
  line-height: 150%;
  display: inline-block;
  width: 641.9px;
  height: 35.4px;
}
.check-out-bootstrap1,
.count-me-in1,
.im-in-for1,
.im-into-front-end1 {
  top: 503.7px;
  left: 677.9px;
  width: 538px;
}
.check-out-bootstrap1,
.count-me-in1,
.im-in-for1 {
  top: 619px;
  width: 616.8px;
  height: 34.3px;
}
.count-me-in1,
.im-in-for1 {
  top: 848.6px;
  left: 676.7px;
  width: 290.1px;
  height: 35.4px;
}
.im-in-for1 {
  top: 966.3px;
  width: 151.9px;
  height: 34.3px;
}
.by-the-way1 {
  margin: 0;
}
.by-the-way-container1,
.try-contributing-to1 {
  position: absolute;
  top: 716.1px;
  left: 683.6px;
  line-height: 150%;
  display: inline-block;
  width: 532.2px;
  height: 70.8px;
}
.try-contributing-to1 {
  top: 388.3px;
  width: 368.9px;
  height: 35.4px;
}
.chat-footer-child4 {
  position: absolute;
  top: 0;
  left: 155.4px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gray-1200);
  width: 563.8px;
  height: 58px;
}
.message7 {
  position: absolute;
  top: 11.6px;
  left: 196px;
  line-height: 150%;
  display: inline-block;
  width: 99.8px;
  height: 34.8px;
}
.image-1-icon6,
.mic-1-icon6,
.plus-thin-16 {
  position: absolute;
  top: 0;
  left: 0;
  width: 58px;
  height: 58px;
  overflow: hidden;
}
.image-1-icon6,
.mic-1-icon6 {
  left: 738.9px;
}
.image-1-icon6 {
  left: 77.7px;
}
.chat-footer6,
.nav-bar-icon1 {
  position: absolute;
  top: 1072px;
  left: 547px;
  width: 796.9px;
  height: 58px;
}
.nav-bar-icon1 {
  top: 46px;
  left: 625px;
  width: 641px;
  height: 121px;
  object-fit: contain;
}
.satck-item,
.techstack-item {
  position: absolute;
  top: 83.8px;
  left: 0;
  background-color: var(--color-gainsboro-400);
  width: 426px;
  height: 379px;
}
.techstack-item {
  text-decoration: none;
  top: 0;
  -webkit-backdrop-filter: blur(16.1px);
          backdrop-filter: blur(16.1px);
  border-radius: var(--br-23xl);
  background-color: var(--color-gainsboro-500);
  border: 2px solid var(--color-whitesmoke-300);
  box-sizing: border-box;
  width: 400px;
  height: 100px;
}
.tech-stack-community3 {
  position: absolute;
  top: 26px;
  left: 34px;
  font-size: var(--font-size-13xl);
  line-height: 150%;
  font-weight: 600;
  font-family: var(--font-saira);
  color: var(--color-teal-200);
  text-align: left;
}
.frame-child36,
.techstack1 {
  position: absolute;
  height: 100px;
}
.techstack1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 174.8px;
  left: 6.1px;
  width: 409.6px;
}
.frame-child36 {
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(16.1px);
          backdrop-filter: blur(16.1px);
  border-radius: var(--br-23xl);
  background-color: var(--color-gainsboro-500);
  border: 2px solid var(--color-whitesmoke-300);
  box-sizing: border-box;
  width: 400px;
}
.organizer-community5 {
  position: absolute;
  top: 26px;
  left: 37px;
  font-size: var(--font-size-13xl);
  line-height: 150%;
  font-weight: 600;
  font-family: var(--font-saira);
  color: var(--color-teal-200);
  text-align: left;
}
.rectangle-parent21 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 297px;
  left: 6.1px;
  width: 409.6px;
  height: 100px;
}
.frame-child37,
.satck-inner1 {
  position: absolute;
  top: 0;
  height: 153px;
}
.frame-child37 {
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset,
    0 4px 4px rgba(0, 0, 0, 0.25) inset;
  border-radius: var(--br-23xl);
  background-color: var(--color-teal-200);
  border: 2px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 400px;
}
.satck-inner1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  left: 7.2px;
  width: 409.6px;
}
.connect-with-students1,
.university-community3 {
  position: absolute;
  top: 13px;
  left: 41px;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 341px;
}
.connect-with-students1 {
  top: 61px;
  left: 69.6px;
  font-size: var(--font-size-mid);
  font-weight: 300;
  width: 308.2px;
}
.satck1,
.uni-commun1 {
  position: absolute;
  left: 0;
}
.satck1 {
  top: 412px;
  width: 426px;
  height: 462.8px;
  font-size: var(--font-size-13xl);
}
.uni-commun1 {
  top: 142px;
  width: 1386px;
  height: 1166.1px;
}
.attnorg-ui-btn-inner8,
.frame-child38 {
  position: absolute;
  left: 0;
  width: 150px;
  height: 30px;
}
.frame-child38 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-teal-200);
}
.attnorg-ui-btn-inner8 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 1px;
}
.attendee-ui7 {
  position: absolute;
  top: 4px;
  left: 34px;
  line-height: 150%;
  font-weight: 100;
}
.frame-child39 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--static-static-white);
  width: 150px;
  height: 30px;
}
.organizer-ui7 {
  position: absolute;
  top: 3px;
  left: 34px;
  line-height: 150%;
  font-weight: 100;
}
.rectangle-parent22 {
  position: absolute;
  top: 0;
  left: 162px;
  width: 150px;
  height: 30px;
  color: var(--color-teal-200);
}
.attnorg-ui-btn10 {
  position: absolute;
  top: 0;
  left: 473px;
  width: 312px;
  height: 31px;
  font-size: var(--font-size-mini);
}
.virtual-community1 {
  position: absolute;
  top: 22638px;
  left: calc(50% - 693px);
  width: 1386px;
  height: 1308.1px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}

.join-child1 {
  position: absolute;
  top: 0;
  left: calc(50% - 84.5px);
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 169px;
  height: 61px;
}
.join-now1 {
  position: absolute;
  top: 6px;
  left: calc(50% - 67.5px);
  line-height: 150%;
}
.join-wrapper1,
.join5 {
  position: absolute;
  width: 169px;
  height: 61px;
}
.join5 {
  top: 0;
  left: calc(50% - 84.5px);
}
.join-wrapper1 {
  cursor: pointer;
  text-decoration: none;
  top: 38px;
  left: 1185px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--static-static-white);
  font-family: var(--font-roboto);
}

.instaicon-1,
.linkedinicon-1 {
  position: absolute;
  width: 70px;
  cursor: pointer;
}
.linkedinicon-1 {
  top: 5721.4px;
  left: 1205px;
  height: 70px;
}
.instaicon-1 {
  top: 5727.4px;
  left: 1286px;
  height: 60px;
}
.synclyfy-all-rights {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
}
.trademark {
  top: 5818.4px;
  left: calc(50% - 156.5px);
  width: 339px;
  height: 50px;
}
.layer-2-icon,
.line-icon,
.trademark {
  position: absolute;
}
.line-icon {
  top: 5785.9px;
  left: 25px;
  width: 1460px;
  height: 20px;
}
.layer-2-icon {
  top: 5722.4px;
  left: 1124px;
  width: 70px;
  height: 66px;
  cursor: pointer;
}
.footer-child {
  position: absolute;
  top: calc(50% - 2934.2px);
  left: 0;
  width: 392px;
  height: 5852.4px;
  object-fit: cover;
}
.footer {
  position: absolute;
  top: 28172.6px;
  left: -36px;
  width: 1485px;
  height: 5868.4px;
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
  font-family: var(--font-roboto);
}
.frame-child {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 200px;
  background-color: var(--color-teal-200);
  width: 1219px;
  height: 364px;
}
.before-footer-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 1239px;
  height: 384px;
}
.discover-the-most {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 300;
  display: inline-block;
  width: 818px;
}
.discover-the-most-exciting-tec-wrapper {
  position: absolute;
  top: 92px;
  left: calc(50% - 378.5px);
  width: 740px;
  height: 200px;
}
.button {
  position: absolute;
  top: 22px;
  left: 12px;
  line-height: 150%;
}
.stylesecondary-smallfalse {
  position: absolute;
  top: 15.8px;
  left: -0.2px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.4));
  border-radius: var(--br-11xl);
  border: 0.5px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 165.5px;
  height: 68.5px;
}
.actions,
.loginsignup-3 {
  position: absolute;
  height: 96px;
}
.actions {
  top: 0;
  left: 109px;
  width: 165px;
  cursor: pointer;
}
.loginsignup-3 {
  top: 433px;
  left: 428px;
  width: 192px;
  font-size: var(--font-size-base);
  color: var(--color-teal-200);
  font-family: var(--font-roboto);
}
.before-footer {
  position: absolute;
  top: 33215px;
  left: 90px;
  width: 1239px;
  height: 529px;
  color: var(--static-static-white);
}
.the-founders {
  position: absolute;
  top: 0;
  left: calc(50% - 190px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
}
.team-bg-tile,
.urooba-info-child {
  position: absolute;
  border-radius: var(--br-31xl);
}
.team-bg-tile {
  top: 282px;
  left: calc(50% - 652px);
  width: 1304px;
  height: 452px;
  object-fit: cover;
}
.urooba-info-child {
  top: 0;
  left: 0;
  background-color: var(--color-whitesmoke-200);
  width: 427px;
  height: 149.5px;
}
.co-founder,
.urooba-gohar {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.urooba-gohar {
  top: 12px;
  left: 133px;
  font-weight: 500;
  width: 193px;
  height: 44.8px;
}
.co-founder {
  top: 46.9px;
  left: 160px;
  font-size: var(--font-size-xl);
  font-weight: 100;
  width: 107px;
  height: 29.9px;
}
.urooba-icons,
.urooba-info {
  position: absolute;
  top: 81.7px;
  left: 108px;
  width: 219px;
  height: 49.8px;
}
.urooba-info {
  top: 720.5px;
  left: 58px;
  width: 427px;
  height: 149.5px;
}
.urooba-tile-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 503px;
  height: 646.8px;
  object-fit: cover;
}
.urooba-tile {
  position: absolute;
  top: 189px;
  left: 663px;
  width: 503px;
  height: 870px;
}
.aimal-info-rect-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-whitesmoke-200);
  width: 427px;
  height: 154.1px;
}
.aimal-khan,
.founder-ceo {
  position: absolute;
  top: 12.4px;
  left: 133px;
  line-height: 150%;
  font-weight: 500;
  display: inline-block;
  width: 160px;
  height: 46.2px;
}
.founder-ceo {
  top: 48.3px;
  left: 138px;
  font-size: var(--font-size-xl);
  font-weight: 100;
  width: 139px;
  height: 30.8px;
}
.aimal-icons {
  position: absolute;
  top: 83.1px;
  left: 108px;
  width: 222px;
  height: 53.4px;
}
.aimal-info-rect,
.aimal-tile-icon {
  position: absolute;
  top: 715.9px;
  left: 38px;
  width: 427px;
  height: 154.1px;
}
.aimal-tile-icon {
  top: 0;
  left: 0;
  width: 503px;
  height: 666.6px;
  object-fit: cover;
}
.aimal-tile {
  position: absolute;
  top: 189px;
  left: 117px;
  width: 503px;
  height: 870px;
}
.founders {
  position: absolute;
  top: 31929px;
  left: calc(50% - 652px);
  width: 1304px;
  height: 1059px;
  font-size: var(--font-size-11xl);
}
.meet-catbot-your {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 600;
}
.meet-catbot-your-purr-fect-te-wrapper {
  position: absolute;
  top: 0;
  left: 64px;
  width: 1270px;
  height: 110px;
}
.introducing-catbot {
  font-weight: 100;
}
.introducing-catbot-hebbonn-container {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  display: inline-block;
  width: 590px;
}
.introducing-catbot-hebbonn-ou-wrapper {
  position: absolute;
  top: 247px;
  left: 69px;
  width: 607px;
  height: 515px;
  font-size: var(--font-size-11xl);
}
.cat-2-icon,
.chat-ui-icon {
  position: absolute;
  object-fit: cover;
}
.cat-2-icon {
  top: 55px;
  left: 588px;
  width: 900px;
  height: 900px;
}
.chat-ui-icon {
  top: 1151px;
  left: calc(50% - 448px);
  width: 896px;
  height: 1475px;
}
.chabot {
  position: absolute;
  top: 25601px;
  left: -48px;
  width: 1488px;
  height: 2725px;
}
.ranks-communities-based {
  font-weight: 300;
}
.leaderboard-inspiring-excelle,
.the-synclyfy-leaderboard-container {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.the-synclyfy-leaderboard-container {
  top: 447px;
  left: 5px;
  width: 714px;
}
.leaderboard-inspiring-excelle {
  top: 0;
  left: 0;
  font-size: var(--font-size-41xl);
  font-weight: 600;
  width: 1244px;
}
.leader-board-icon {
  position: absolute;
  top: 198px;
  left: 1001px;
  border-radius: var(--br-31xl);
  width: 375px;
  height: 812px;
  overflow: hidden;
  object-fit: cover;
}
.leader-board {
  position: absolute;
  top: 24207px;
  left: 29px;
  width: 1376px;
  height: 1010px;
  font-size: var(--font-size-11xl);
}
.join-the-virtual {
  position: absolute;
  top: 10px;
  left: calc(50% - 409.5px);
  line-height: 150%;
  font-weight: 600;
}
.join-the-virtual-communities-wrapper,
.virtaul-community-attendee {
  position: absolute;
  top: 0;
  left: calc(50% - 397.5px);
  width: 795px;
  height: 110px;
}
.virtaul-community-attendee {
  top: 22361px;
  left: calc(50% - 398px);
}
.challenge-accepted-inter-univ {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 1158px;
}
.challenge-accepted-inter-univ-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1178px;
  height: 200px;
}
.uni-hackathons-child,
.uni-hackathons-item {
  position: absolute;
  top: 628px;
  left: 124px;
  width: 516px;
  height: 374px;
  object-fit: cover;
}
.uni-hackathons-item {
  left: 688px;
}
.ready-to-show-container {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  display: inline-block;
  width: 818px;
}
.ready-to-show-off-your-skills-wrapper {
  position: absolute;
  top: 255px;
  left: 372px;
  width: 838px;
  height: 245px;
  font-size: var(--font-size-11xl);
}
.group-child,
.uni-hackathons {
  position: absolute;
}
.uni-hackathons {
  top: 20881px;
  left: 16px;
  width: 1210px;
  height: 1002px;
}
.group-child {
  top: 332.7px;
  left: 0;
  width: 995.7px;
  height: 291.1px;
}
.connect-collaborate,
.engage-with-fellow {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.engage-with-fellow {
  top: 240px;
  left: 30.2px;
  font-weight: 100;
  width: 971.9px;
  height: 160.4px;
}
.connect-collaborate {
  top: 0;
  left: 29.7px;
  font-size: var(--font-size-41xl);
  font-weight: 600;
  width: 862.6px;
  height: 213.9px;
}
.frame-parent {
  position: absolute;
  top: 281.7px;
  left: 0;
  width: 1002.1px;
  height: 623.8px;
}
.chat,
.chat1,
.chats-2-icon {
  position: absolute;
  height: 992px;
}
.chat1,
.chats-2-icon {
  top: 0;
}
.chats-2-icon {
  left: 950px;
  width: 458px;
  overflow: hidden;
  object-fit: cover;
}
.chat,
.chat1 {
  left: 0;
  width: 1408px;
}
.chat {
  top: 19489px;
  font-size: var(--font-size-11xl);
}
.curious-whos-joining,
.get-notified-about-container {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.curious-whos-joining {
  top: 0;
  left: -68px;
  font-weight: 600;
  width: 1163px;
}
.get-notified-about-container {
  top: 473px;
  left: -64px;
  font-size: var(--font-size-11xl);
  width: 559px;
}
.netwrksocail-media-child {
  position: absolute;
  top: 243px;
  left: 586px;
  border-radius: 0 var(--br-81xl) 0 var(--br-81xl);
  width: 725px;
  height: 685.4px;
  object-fit: cover;
}
.shout-out-your {
  position: absolute;
  top: 1064px;
  left: calc(50% - 311.5px);
  font-size: 49px;
  line-height: 150%;
}
.netwrksocail-media {
  position: absolute;
  top: 0;
  left: 64px;
  width: 1217px;
  height: 1483px;
}
.went-to-an-container {
  position: absolute;
  top: 1499px;
  left: 820px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  display: inline-block;
  width: 559px;
}
.fellow-notiff-and-social-media-child {
  position: absolute;
  top: 1269px;
  left: 0;
  border-radius: 0 var(--br-81xl) 0 var(--br-81xl);
  width: 725px;
  height: 685.4px;
  object-fit: cover;
}
.fellow-notiff-and-social-media {
  position: absolute;
  top: 17135px;
  left: 32px;
  width: 1379px;
  height: 1954.4px;
  font-size: var(--font-size-46xl);
}
.if-missed-never {
  position: absolute;
  top: 0;
  left: 125px;
  line-height: 150%;
  font-weight: 600;
}
.stay-in-the-container {
  position: absolute;
  top: 127px;
  left: 190.5px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  text-align: left;
  display: inline-block;
  width: 782px;
  height: 182px;
}
.calendernotif,
.notification-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1163px;
  height: 1035px;
}
.notification-icon {
  top: 338px;
  height: 697px;
  object-fit: cover;
}
.notification {
  position: absolute;
  top: 15651px;
  left: 139px;
  width: 1163px;
  height: 1035px;
  text-align: center;
  font-size: var(--font-size-46xl);
}
.but-just-forgot {
  margin: 0;
}
.but-just-forgot-container {
  position: absolute;
  top: 72px;
  left: 10px;
  font-size: var(--font-size-21xl);
  line-height: 150%;
}
.attended-event {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
  font-weight: 600;
}
.attended-event-icon {
  position: absolute;
  top: -22px;
  left: -43px;
  width: 760px;
  height: 632.1px;
  object-fit: cover;
}
.but-curious-about-container,
.missed-event {
  position: absolute;
  left: 367px;
  line-height: 150%;
}
.missed-event {
  top: 670px;
  font-weight: 600;
}
.but-curious-about-container {
  top: 765px;
  font-size: var(--font-size-21xl);
}
.arrow-down-right-icon {
  position: absolute;
  top: 635.5px;
  left: 684.5px;
  width: 48.9px;
  height: 48.9px;
  overflow: hidden;
  object-fit: contain;
}
.attened-or-miss {
  position: absolute;
  top: 236px;
  left: 345px;
  width: 675px;
  height: 458px;
}
.arrow-down-right-icon1 {
  position: absolute;
  top: 210px;
  left: 225px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.event-materials {
  position: absolute;
  top: 14184px;
  left: 34px;
  width: 1020px;
  height: 694px;
  font-size: var(--font-size-46xl);
}
.explore-get-exact,
.with-our-location {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.explore-get-exact {
  top: 0;
  left: -101px;
  font-weight: 600;
  white-space: pre-wrap;
  width: 1163px;
}
.with-our-location {
  top: 98px;
  left: 270px;
  font-size: var(--font-size-11xl);
  font-weight: 100;
  width: 677px;
}
.explore-det-exact {
  position: absolute;
  top: 0;
  left: 0;
  width: 1163px;
  height: 376px;
}
.explore-img-icon {
  position: absolute;
  top: 376px;
  left: calc(50% - 577px);
  width: 1163px;
  height: 785px;
  object-fit: cover;
}
.transport {
  position: absolute;
  top: 12648px;
  left: 130px;
  width: 1172px;
  height: 1161px;
  font-size: var(--font-size-46xl);
}
.bg-1-icon {
  position: absolute;
  top: 368px;
  left: 0;
  border-radius: var(--br-31xl);
  width: 1313px;
  height: 1078px;
  overflow: hidden;
  object-fit: cover;
}
.fed-up-with,
.weve-got-you-container {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.fed-up-with {
  top: 0;
  left: 38px;
  font-size: var(--font-size-46xl);
  font-weight: 600;
  width: 1075px;
}
.weve-got-you-container {
  top: 10px;
  left: 10px;
  width: 539px;
}
.weve-got-you-covered-enjoy-s-wrapper {
  position: absolute;
  top: 88px;
  left: 698px;
  width: 559px;
  height: 155px;
}
.registeration-form1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
  font-weight: 100;
}
.reg-from-logo {
  position: absolute;
  top: 74px;
  left: 177px;
  width: 272px;
  height: 48px;
  color: var(--text-primary);
}
.name-child {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--color-teal-100);
  width: 450px;
  height: 58px;
}
.email,
.name {
  left: 40px;
  width: 450px;
  height: 58px;
}
.name {
  position: absolute;
  top: 239px;
}
.email {
  top: 366px;
}
.address,
.email,
.phone {
  position: absolute;
}
.phone {
  top: 493px;
  left: 40px;
  width: 450px;
  height: 58px;
}
.address {
  top: 5px;
  left: 45px;
  line-height: 150%;
  font-weight: 100;
}
.adress {
  position: absolute;
  top: 620px;
  left: 40px;
  width: 450px;
  height: 58px;
}
.registr-submit-child {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--color-teal-100);
  width: 333px;
  height: 50px;
}
.submit {
  position: absolute;
  top: 1px;
  left: 113px;
  line-height: 150%;
  font-weight: 100;
}
.registr-submit {
  position: absolute;
  top: 747px;
  left: 99px;
  width: 333px;
  height: 50px;
}
.synclyfybg-1-icon {
  position: absolute;
  top: 58px;
  left: 74px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.payments-bg-rectangle,
.registeration-form {
  position: absolute;
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  border-radius: var(--br-31xl);
  background-color: var(--color-rosybrown-100);
  width: 530px;
  height: 907px;
}
.registeration-form {
  top: 453px;
  left: calc(50% - 587.5px);
  box-shadow: 16px 16px 11px rgba(0, 0, 0, 0.25);
  font-size: var(--font-size-13xl);
  color: var(--static-static-white);
}
.payments-bg-rectangle {
  top: 0;
  left: 0;
  box-shadow: 9px 11px 4px rgba(0, 0, 0, 0.25);
}
.secure-payments {
  position: absolute;
  top: 75px;
  left: 204px;
  width: 260px;
  height: 48px;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
}
.layer-1-icon {
  position: absolute;
  height: 12.96%;
  width: 93.74%;
  top: 44.32%;
  right: 2.81%;
  bottom: 42.72%;
  left: 3.45%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.or {
  position: absolute;
  top: 123px;
  left: 213px;
  line-height: 150%;
  font-weight: 100;
}
.payment-gateway-logo-vec-16772 {
  position: absolute;
  top: 314px;
  left: 49px;
  width: 470px;
  height: 470px;
  overflow: hidden;
}
.easypaisa-child {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--static-static-white);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 451px;
  height: 59px;
}
.pay-with {
  position: absolute;
  top: 5px;
  left: 62px;
  line-height: 150%;
  font-weight: 100;
}
.b39f879357-easypaisa-1-icon {
  position: absolute;
  top: 8px;
  left: 293px;
  width: 93px;
  height: 42px;
  object-fit: cover;
}
.easypaisa {
  position: absolute;
  top: 330px;
  left: 70px;
  width: 450px;
  height: 58px;
  color: var(--color-teal-100);
}
.jazz-cah-child {
  position: absolute;
  top: 19.5px;
  left: -0.5px;
  box-shadow: 4px 9px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-21xl);
  background-color: var(--static-static-white);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 451px;
  height: 59px;
}
.pay-with1 {
  position: absolute;
  top: 25px;
  left: 62px;
  line-height: 150%;
  font-weight: 100;
}
.jazzcash-icon {
  position: absolute;
  top: 0;
  left: 315.4px;
  width: 110.4px;
  height: 110.4px;
  object-fit: contain;
}
.jazz-cah {
  top: 220px;
  left: 70px;
  width: 450px;
  height: 110.4px;
  color: var(--color-teal-100);
}
.jazz-cah,
.next,
.pay-next {
  position: absolute;
}
.next {
  top: 1px;
  left: 132px;
  line-height: 150%;
  font-weight: 100;
}
.pay-next {
  top: 738px;
  left: 127px;
  width: 333px;
  height: 50px;
  font-size: var(--font-size-13xl);
}
.synclyfybg-1-icon1 {
  position: absolute;
  top: 59px;
  left: 104px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.payment-form {
  position: absolute;
  top: 462px;
  left: 682px;
  width: 530px;
  height: 1032px;
  color: var(--static-static-white);
}
.registrationpayments {
  position: absolute;
  top: 12166px;
  left: 64px;
  width: 1313px;
  height: 1494px;
  display: none;
}
.vector-icon {
  position: absolute;
  top: 559px;
  left: 642px;
  width: 93px;
  height: 83px;
}
.community-pfp-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-11xl);
  background: linear-gradient(
    180deg,
    rgba(220, 220, 220, 0.56) 89.5%,
    rgba(118, 118, 118, 0.31)
  );
  width: 652.2px;
  height: 604.9px;
}
.speaker-profile,
.with-synclyfys-speaker {
  position: absolute;
  line-height: 150%;
  display: inline-block;
}
.with-synclyfys-speaker {
  top: 278.8px;
  left: 64.1px;
  font-weight: 100;
  width: 551.2px;
  height: 198.8px;
}
.speaker-profile {
  top: 157.8px;
  left: calc(50% - 187.3px);
  font-size: var(--font-size-31xl);
  font-weight: 500;
  text-align: left;
  width: 373.5px;
  height: 78.9px;
}
.community-pfp {
  position: absolute;
  top: 298px;
  left: 723.8px;
  width: 652.2px;
  height: 604.9px;
  text-align: center;
}
.create-a-personalized,
.user-profile1 {
  position: absolute;
  line-height: 150%;
}
.user-profile1 {
  top: 148px;
  left: 174px;
  font-weight: 500;
}
.create-a-personalized {
  top: 236px;
  left: 48px;
  font-size: var(--font-size-6xl);
  font-weight: 100;
  text-align: center;
  display: inline-block;
  width: 524px;
  height: 189px;
}
.personalpfp-1,
.user-pf-logo {
  position: absolute;
}
.user-pf-logo {
  top: 61px;
  left: 283.4px;
  width: 69.6px;
  height: 83.8px;
}
.personalpfp-1 {
  top: -6.3px;
  left: 274.6px;
  width: 89.4px;
  height: 89.4px;
  overflow: hidden;
  display: none;
}
.user-profile {
  position: absolute;
  top: 298px;
  left: 0;
  width: 652.2px;
  height: 604.9px;
  font-size: var(--font-size-31xl);
}
.user-pf-logo1 {
  position: absolute;
  top: 348px;
  left: 965px;
  width: 66.1px;
  height: 79.7px;
}
.button1 {
  position: absolute;
  top: 22px;
  left: 60.5px;
  line-height: 150%;
}
.stylesecondary-smallfalse1 {
  position: absolute;
  top: 15.8px;
  left: -0.2px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.4));
  border-radius: var(--br-11xl);
  border: 0.5px solid var(--text-primary);
  box-sizing: border-box;
  width: 165.5px;
  height: 68.5px;
}
.actions1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 165px;
  height: 96px;
}
.button2 {
  position: absolute;
  top: 22px;
  left: 55px;
  line-height: 150%;
}
.stylesecondary-smallfalse2 {
  position: absolute;
  top: 15.8px;
  left: -0.2px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.4));
  border-radius: var(--br-11xl);
  border: 0.5px solid var(--color-teal-100);
  box-sizing: border-box;
  width: 165.5px;
  height: 68.5px;
}
.actions2,
.loginsignup-2 {
  position: absolute;
  height: 96px;
}
.actions2 {
  top: 0;
  left: 192px;
  width: 165px;
  color: var(--color-teal-100);
}
.loginsignup-2 {
  top: 940px;
  left: 509px;
  width: 357px;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
}
.the-profiles-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-teal-100);
  width: 422px;
  height: 143px;
}
.the-profiles2 {
  position: absolute;
  top: 23px;
  left: 28px;
  line-height: 150%;
  font-weight: 600;
}
.the-profiles-wrapper,
.the-profiles1 {
  position: absolute;
  top: 0;
  width: 422px;
  height: 143px;
}
.the-profiles1 {
  left: 0;
  box-shadow: 0 15px 13px #ccc;
}
.the-profiles-wrapper {
  left: 477px;
  font-size: var(--font-size-46xl);
  color: var(--static-static-white);
}
.the-profiles {
  position: absolute;
  top: 4127px;
  left: 32px;
  width: 1376px;
  height: 1036px;
  display: none;
  font-size: var(--font-size-6xl);
}
.events-feed-child {
  position: absolute;
  top: 188px;
  left: 0;
  border-radius: var(--br-31xl);
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(11, 199, 199, 0.5) -40.92deg,
    rgba(20, 116, 105, 0.5) 61.8deg,
    rgba(82, 164, 70, 0.5) 189deg,
    rgba(11, 199, 199, 0.5) 319.08deg,
    rgba(20, 116, 105, 0.5) 421.8deg
  );
  width: 1376px;
  height: 909px;
}
.events-feed-inner {
  position: absolute;
  top: 269px;
  object-fit: cover;
}
.events-feed-item {
  top: 269px;
  left: 42px;
  border-radius: var(--br-31xl) 195px 0 var(--br-31xl);
  width: 695px;
  height: 746px;
}
.events-feed-item,
.rectangle-icon {
  position: absolute;
  object-fit: cover;
}
.events-feed-inner,
.rectangle-icon {
  left: 833px;
  border-radius: 0 59px 0 var(--br-131xl);
  width: 500px;
  height: 340px;
}
.rectangle-icon {
  top: 675px;
  border-radius: var(--br-131xl) 0 var(--br-31xl) 0;
}
.discover-events-tailored-container {
  position: absolute;
  top: 98px;
  left: 178.5px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  font-weight: 100;
  text-align: left;
}
.personilized {
  position: absolute;
  top: 0;
  left: 299px;
  width: 778px;
  height: 188px;
}
.events-feed,
.hidden-pre {
  position: absolute;
  display: none;
}
.events-feed {
  top: 2582px;
  left: 32px;
  width: 1376px;
  height: 1097px;
  text-align: center;
  font-size: var(--font-size-46xl);
}
.hidden-pre {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  font-size: var(--font-size-11xl);
}
.streamline-check-ins-with {
  position: absolute;
  top: 0;
  left: calc(50% - 546px);
  line-height: 150%;
  font-weight: 600;
}
.say-goodbye-to-container {
  position: absolute;
  top: 458px;
  left: 0;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  display: inline-block;
  width: 668px;
}
.d-character-emerging-from-smar-icon {
  position: absolute;
  top: 90px;
  left: 778px;
  width: 640px;
  height: 961px;
  object-fit: cover;
}
.check-ins {
  position: absolute;
  top: 9551px;
  left: calc(50% - 687px);
  width: 1418px;
  height: 1051px;
}
.explore-connect-and {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 818px;
}
.explore-connect-and-grow-wit-wrapper {
  position: absolute;
  top: 163px;
  left: calc(50% - 419px);
  width: 838px;
  height: 245px;
}
.virtaul-events-child {
  position: absolute;
  top: 0;
  left: 330px;
  width: 795px;
  height: 110px;
}
.virtual-events-icon {
  position: absolute;
  top: 581px;
  left: 0;
  border-radius: 16px;
  width: 1440px;
  height: 900px;
  overflow: hidden;
  object-fit: cover;
}
.unlock-the-power {
  position: absolute;
  top: 46px;
  left: 233px;
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
  text-align: left;
}
.virtaul-events {
  position: absolute;
  top: 7623px;
  left: calc(50% - 720px);
  width: 1440px;
  height: 1481px;
  text-align: center;
  font-size: var(--font-size-11xl);
}
.effortless-registration-payme {
  position: absolute;
  top: 0;
  left: calc(50% - 623px);
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 1246px;
}
.streamline-event-management-container {
  position: absolute;
  top: 90px;
  left: calc(50% - 173px);
  font-size: var(--font-size-11xl);
  line-height: 150%;
  display: inline-block;
  width: 724px;
}
.registration {
  position: absolute;
  top: 4325px;
  left: calc(50% - 690px);
  width: 1246px;
  height: 270px;
}
.boost-your-events {
  position: absolute;
  top: 90px;
  left: 2px;
  font-size: var(--font-size-11xl);
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 806px;
}
.promote-and-sponsor-your-event-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 952px;
  height: 225px;
}
.sponsor-event-icon {
  position: absolute;
  top: 410px;
  left: 70px;
  width: 1240px;
  height: 880px;
  object-fit: cover;
}
.search-2-icon {
  position: absolute;
  top: 490px;
  left: 1022px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.ads {
  position: absolute;
  top: 5882px;
  left: 30px;
  width: 1310px;
  height: 1290px;
}
.home-child {
  position: absolute;
  top: 1122px;
  left: calc(50% - 770px);
  border-radius: var(--br-31xl);
  width: 1458px;
  height: 1240px;
}
.synclyfy-curates-events-container {
  position: absolute;
  top: 91px;
  left: 0;
  line-height: 150%;
  display: inline-block;
  width: 725px;
}
.your-personalized-feed {
  position: absolute;
  top: 0;
  left: calc(50% - 362.5px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
}
.description {
  position: absolute;
  top: 2667px;
  left: 32px;
  width: 725px;
  height: 361px;
  font-size: var(--font-size-11xl);
}
.explore-exciting-features-container {
  position: absolute;
  top: 75px;
  left: 0;
  font-size: var(--font-size-46xl);
  line-height: 150%;
  font-weight: 600;
}
.synclyfy-offers-a {
  position: absolute;
  top: 179px;
  left: 437px;
  font-size: 22px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 545px;
  height: 165px;
}
.discover {
  position: absolute;
  top: 2017px;
  left: 28px;
  width: 982px;
  height: 344px;
  font-size: var(--font-size-31xl);
}
.explore-tech-events {
  position: absolute;
  top: 0;
  left: calc(50% - 495.5px);
  line-height: 150%;
  font-weight: 600;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.discover-conferences-workshop {
  position: absolute;
  top: 63px;
  left: calc(50% - 431.5px);
  font-size: var(--font-size-21xl);
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 927px;
}
.after-hero {
  position: absolute;
  top: 1361px;
  left: calc(50% - 438px);
  width: 991px;
  height: 183px;
  font-size: var(--font-size-31xl);
  color: var(--color-darkslategray-400);
}
.home-item {
  position: absolute;
  top: 236px;
  left: 430px;
  border-radius: var(--br-31xl);
  width: 843.2px;
  height: 735.9px;
  object-fit: contain;
}
.loginsignup-1 {
  position: absolute;
  top: 711px;
  left: 853px;
  width: 357px;
  height: 96px;
  display: none;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
}
.stylesecondary-smallfalse5 {
  position: absolute;
  top: calc(50% - 34px);
  left: calc(50% - 82.5px);
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 165px;
  height: 68px;
}
.actions-wrapper {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 619px;
  left: 948px;
  width: 165px;
  height: 96px;
  font-size: var(--font-size-base);
  color: var(--static-static-white);
  font-family: var(--font-roboto);
}
.hero-para-child {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: var(--blur-glass);
          backdrop-filter: var(--blur-glass);
  border-radius: var(--br-xl);
  background-color: rgba(20, 116, 105, 0.15);
  width: 754px;
  height: 304px;
}
.unite-with-tech-container {
  position: absolute;
  top: 21.3px;
  left: 26px;
  line-height: 150%;
  display: inline-block;
  width: 713px;
  height: 255px;
}
.hero-para {
  position: absolute;
  top: 271px;
  left: 654px;
  width: 754px;
  height: 304px;
  font-size: var(--font-size-13xl);
  color: var(--color-teal-200);
  font-family: var(--font-ruda);
}
.master-the-art-of-networking-wrapper {
  position: absolute;
  top: 0;
  left: 311px;
  width: 845px;
  height: 110px;
  font-size: var(--font-size-41xl);
  color: var(--text-primary);
}
.group-parent {
  position: absolute;
  top: 203px;
  left: 0;
  width: 1468px;
  height: 1285px;
}
.used-our-notifications {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 150%;
  font-weight: 100;
  display: inline-block;
  width: 442px;
}
.frame-item {
  position: absolute;
}
.used-our-notifications-and-soc-wrapper {
  position: absolute;
  top: 683px;
  left: 70px;
  width: 462px;
  height: 290px;
}
.frame-item {
  top: 10px;
  left: 10px;
  border-radius: var(--br-11xl);
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  width: 189px;
  height: 80px;
}
.netwrking-inner {
  position: absolute;
  top: 1021px;
  left: 161px;
  width: 209px;
  height: 100px;
}
.learn-more-wrapper {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 1039px;
  left: 176px;
  width: 179px;
  height: 65px;
  color: inherit;
}
.netwrking {
  top: 28555px;
  left: -17px;
  width: 1468px;
  height: 1488px;
  font-size: var(--font-size-11xl);
  color: var(--static-static-white);
}
.frame-inner,
.netwrking {
  position: absolute;
}
.testimonials-child {
  position: absolute;
  top: 561px;
  left: 0;
  width: 1815px;
  height: 1525px;
}
.frame-inner {
  top: 122.1px;
  left: 29.5px;
  border-radius: var(--br-11xl);
  width: 1107px;
  height: 1276.5px;
  object-fit: contain;
}
.testimonials,
.vector-parent {
  position: absolute;
}
.vector-parent {
  top: 0;
  left: 858px;
  width: 1596px;
  height: 1572.9px;
}
.testimonials {
  top: 29877px;
  left: -496px;
  width: 2454px;
  height: 2086px;
}
.design-marketplace,
.discover-a-wide {
  position: absolute;
  top: 0;
  left: 344px;
  line-height: 150%;
  font-weight: 600;
}
.discover-a-wide {
  top: 140px;
  left: 185.5px;
  font-size: var(--font-size-11xl);
  font-weight: 100;
  display: inline-block;
  width: 869px;
}
.design-marketplace-icon {
  position: absolute;
  top: 325px;
  left: calc(50% - 620px);
  width: 1240px;
  height: 880px;
  object-fit: cover;
}
.search-1-icon {
  position: absolute;
  top: 385px;
  left: 871px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.design {
  position: absolute;
  top: 10983px;
  left: 89px;
  width: 1240px;
  height: 1205px;
}
.pricing {
  position: absolute;
  top: 44px;
  left: 1005px;
  line-height: 150%;
  cursor: pointer;
}
.nav-bar1 {
  position: absolute;
  top: 31px;
  left: calc(50% - 666px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
}
.logo-child,
.logo-item {
  position: absolute;
  height: 74.42%;
  width: 78%;
  top: 22.72%;
  right: 0;
  bottom: 2.86%;
  left: 22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.logo-item {
  height: 100%;
  width: 19.18%;
  top: 0;
  right: 80.82%;
  bottom: 0;
  left: 0;
  object-fit: cover;
}
.logo {
  height: 27.95%;
  width: 22.76%;
  top: 36.05%;
  right: 72.37%;
  bottom: 36%;
  left: 4.87%;
}
.home1,
.logo,
.nav-bar {
  position: absolute;
}
.nav-bar {
  top: -20px;
  left: -12px;
  width: 1420px;
  height: 200px;
  font-size: var(--font-size-13xl);
}
.home1 {
  top: 0;
  left: 0;
  background-color: #d7e4fa;
  width: 1440px;
  height: 34485px;
}
.home,
.home-wrapper {
  background-color: var(--color-lavender-100);
}
.home-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 34937px;
}
.home {
  width: 100%;
  position: relative;
  height: 34133px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.enterprise-child {
  position: absolute;
  top: 548px;
  left: 39px;
  filter: blur(150px);
  border-radius: var(--br-5xl);
  background-color: var(--color-whitesmoke-400);
  width: 363px;
  height: 137px;
}
.enterprise-item {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 509px;
  height: 941px;
}
.enterprise-inner {
  position: absolute;
  top: 160px;
  left: 40px;
  max-height: 100%;
  width: 366px;
}
.b,
.group-icon {
  position: absolute;
}
.group-icon {
  top: 40px;
  left: 40px;
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.b {
  top: 75px;
  left: 154px;
  font-size: var(--font-size-21xl);
}
.organizer-page-creation {
  position: absolute;
  top: 2px;
  left: 0;
  font-weight: 500;
}
.group-item {
  position: absolute;
  top: 0;
  left: 400px;
  width: 24px;
  height: 24px;
}
.organizer-page-creation-parent,
.premium-event-listing-parent {
  position: absolute;
  top: 190px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.premium-event-listing-parent {
  top: 232px;
}
.full-custom-registration-forms-parent,
.group-child4,
.in-app-payments-1-cutticket-parent {
  position: absolute;
  top: 274px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.group-child4,
.in-app-payments-1-cutticket-parent {
  top: 316px;
}
.group-child4 {
  top: 42px;
  left: 400px;
  width: 24px;
}
.event-feedback-forms-parent,
.qr-check-ins-unlimited-parent {
  position: absolute;
  top: 358px;
  left: 39px;
  width: 424px;
  height: 66px;
}
.qr-check-ins-unlimited-parent {
  top: 442px;
  height: 24px;
}
.advance-event-analytics {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
}
.advance-event-analytics-wrapper {
  position: absolute;
  top: 402px;
  left: 39px;
  width: 198px;
  height: 20px;
}
.premium-designs-templates-parent,
.virtual-events-unlimited-parent {
  position: absolute;
  top: 484px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.premium-designs-templates-parent {
  top: 526px;
}
.all-discussion-boards-parent,
.email-support-with-24-hr-respo-parent {
  position: absolute;
  top: 568px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.all-discussion-boards-parent {
  top: 610px;
}
.embed-social-media-page-widget-parent,
.leaderboard-ranking-parent {
  position: absolute;
  top: 689px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.leaderboard-ranking-parent {
  top: 730px;
}
.access-to-organizers-virtual-c-parent,
.ads-and-sponserships-parent {
  position: absolute;
  top: 775px;
  left: 39px;
  width: 424px;
  height: 24px;
}
.ads-and-sponserships-parent {
  top: 652px;
}
.join-waitlist,
.rectangle-div {
  position: absolute;
  left: 0;
  width: 362px;
}
.rectangle-div {
  top: 0;
  border-radius: var(--br-42xl);
  background-color: var(--color-darkslategray-500);
  height: 50px;
}
.join-waitlist {
  top: 11px;
  font-weight: 500;
  display: inline-block;
}
.rectangle-parent {
  position: absolute;
  top: 863px;
  left: 73px;
  width: 362px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.month {
  position: absolute;
  top: 94px;
  left: 277px;
  font-weight: 500;
  color: var(--color-darkgray-300);
}
.enterprise,
.pro-item {
  position: absolute;
  top: 1549px;
  left: 785px;
  width: 509px;
  height: 941px;
}
.pro-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  height: 852px;
}
.professional {
  position: absolute;
  top: 47px;
  left: 154px;
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-family: var(--font-poppins);
}
.organizer-page-creation-group,
.priority-event-listing-parent {
  position: absolute;
  top: 190px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.priority-event-listing-parent {
  top: 232px;
}
.advanced-registration-forms-parent,
.in-app-payments-3-cutticket-parent {
  position: absolute;
  top: 274px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.in-app-payments-3-cutticket-parent {
  top: 316px;
}
.event-feedback-forms-group,
.pro-child2,
.qr-check-ins-1000-parent {
  position: absolute;
  top: 358px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.pro-child2,
.qr-check-ins-1000-parent {
  top: 442px;
}
.pro-child2 {
  top: 400px;
  left: 438px;
  width: 24px;
}
.intermediate-event-analytics-wrapper {
  position: absolute;
  top: 402px;
  left: 31px;
  width: 234px;
  height: 20px;
}
.custom-designs-templates-parent,
.virtual-events-8-eventsmonth-parent {
  position: absolute;
  top: 484px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.custom-designs-templates-parent {
  top: 526px;
}
.discussion-boards-speaker-a-parent,
.email-support-with-48-hr-respo-parent {
  position: absolute;
  top: 568px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.discussion-boards-speaker-a-parent {
  top: 610px;
}
.ads-and-sponserships-group,
.embed-social-media-page-widget-group,
.rectangle-group {
  position: absolute;
  top: 689px;
  left: 38px;
  width: 424px;
  height: 24px;
}
.ads-and-sponserships-group,
.rectangle-group {
  top: 652px;
}
.rectangle-group {
  top: 768px;
  left: 73px;
  width: 362px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.month1 {
  position: absolute;
  top: 94px;
  left: 254px;
  font-weight: 500;
  color: var(--color-darkgray-300);
}
.pro,
.starter-item {
  position: absolute;
  top: 1594px;
  left: 57px;
  width: 509px;
  height: 852px;
}
.starter-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  height: 786px;
}
.event-listing-parent,
.organizer-page-creation-container {
  position: absolute;
  top: 190px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.event-listing-parent {
  top: 232px;
}
.enhanced-registration-forms-parent,
.in-app-payments-5-cutticket-parent {
  position: absolute;
  top: 274px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.in-app-payments-5-cutticket-parent {
  top: 316px;
}
.event-feedback-forms-container,
.qr-check-ins-500-parent {
  position: absolute;
  top: 358px;
  left: 40px;
  width: 424px;
  height: 66px;
}
.qr-check-ins-500-parent {
  top: 442px;
  height: 24px;
}
.basic-event-analytics-wrapper {
  position: absolute;
  top: 402px;
  left: 40px;
  width: 171px;
  height: 20px;
}
.virtual-events-5-eventsmonth-parent {
  position: absolute;
  top: 484px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.email-support-with-72-hr-respo-parent,
.limited-custom-designs-templat-parent {
  position: absolute;
  top: 526px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.email-support-with-72-hr-respo-parent {
  top: 568px;
  left: 42px;
}
.group-child38,
.virtual-events-5-eventsmonth-group {
  position: absolute;
  top: 610px;
  left: 40px;
  width: 424px;
  height: 24px;
}
.group-child38 {
  top: 0;
  left: 17px;
  border-radius: var(--br-42xl);
  background-color: var(--color-darkslategray-500);
  width: 362px;
  height: 50px;
}
.rectangle-container {
  position: absolute;
  top: 705px;
  left: 57px;
  width: 379px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.month2 {
  position: absolute;
  top: 96px;
  left: 234px;
  font-weight: 500;
  color: var(--color-darkgray-300);
}
.free-item,
.starter {
  position: absolute;
  top: 509px;
  left: 785px;
  width: 509px;
  height: 786px;
}
.free-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-teal-200);
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  width: 442px;
  height: 628px;
}
.basic-page-creation-parent,
.group-child39 {
  position: absolute;
  top: 0;
  left: 338px;
  width: 24px;
  height: 24px;
}
.basic-page-creation-parent {
  top: 190px;
  left: 40px;
  width: 362px;
}
.basic-registration-forms-parent,
.event-posting-not-listing-parent {
  position: absolute;
  top: 232px;
  left: 40px;
  width: 362px;
  height: 24px;
}
.basic-registration-forms-parent {
  top: 274px;
}
.basic-post-event-feedback-form-parent,
.in-app-payments-8-cutticket-parent {
  position: absolute;
  top: 316px;
  left: 40px;
  width: 362px;
  height: 24px;
}
.basic-post-event-feedback-form-parent {
  top: 358px;
}
.basic-insights-parent {
  top: 400px;
  left: 40px;
  height: 24px;
}
.basic-insights-parent,
.group-div,
.join-waitlist3 {
  position: absolute;
  width: 362px;
}
.join-waitlist3 {
  top: 11px;
  left: calc(50% - 181px);
  font-weight: 500;
  display: inline-block;
}
.group-div {
  top: 538px;
  left: 40px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.free {
  position: absolute;
  top: 559px;
  left: 164px;
  width: 442px;
  height: 685px;
}
.synclyfy-v1-1-icon {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 1693.5px);
  left: 8px;
  width: 392px;
  height: 200px;
}
.note,
.theese-plans-are {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 150%;
}
.theese-plans-are {
  left: 96px;
  color: var(--text-primary);
}
.note-parent {
  position: absolute;
  top: 296px;
  left: 403px;
  width: 633px;
  height: 48px;
  font-size: var(--font-size-13xl);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}
.pricing1 {
  width: 100%;
  position: relative;
  background-color: #e5fcff;
  border: 1px solid var(--text-primary);
  box-sizing: border-box;
  height: 3419px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--static-static-white);
  font-family: var(--font-montserrat);
}

.org-success-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing2 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.pricing-wrapper {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar2 {
  position: absolute;
  top: 10px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-200);
  width: 1376px;
  height: 138px;
  font-size: var(--font-size-13xl);
}
.synclyfy-v1-1-icon1 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-group {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 533px);
  left: 0;
  width: 392px;
  height: 200px;
}
.success-youve-secured {
  position: absolute;
  top: 158px;
  left: calc(50% - 331px);
  line-height: 150%;
  display: inline-block;
  width: 662px;
}
.check-circle-icon {
  position: absolute;
  top: 251px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.wed-love-your {
  position: absolute;
  top: 362px;
  left: 340px;
  font-size: var(--font-size-xl);
  line-height: 150%;
  display: inline-block;
  width: 831px;
}
.form-child {
  position: absolute;
  top: 0;
  left: calc(50% - 380.5px);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-600);
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 761px;
  height: 582px;
}
.what-features-of {
  position: absolute;
  top: 22px;
  left: calc(50% - 330.5px);
  line-height: 150%;
}
.form-child1,
.form-inner,
.form-item,
.rectangle-input {
  border: 1px solid var(--color-teal-200);
  outline: 0;
  background-color: transparent;
  position: absolute;
  top: 59px;
  left: 56px;
  border-radius: var(--br-xl);
  box-sizing: border-box;
  width: 620px;
  height: 66px;
}
.form-child1,
.form-inner,
.rectangle-input {
  top: 175px;
}
.form-child1,
.rectangle-input {
  top: 291px;
}
.form-child1 {
  top: 434px;
}
.how-do-you,
.how-does-synclyfy {
  position: absolute;
  line-height: 150%;
}
.how-do-you {
  top: 365px;
  left: calc(50% - 343.5px);
  text-align: center;
  display: inline-block;
  width: 688px;
}
.how-does-synclyfy {
  top: 142px;
  left: calc(50% - 347.5px);
}
.are-there-any {
  position: absolute;
  top: 260px;
  left: 126px;
  line-height: 150%;
}
.form {
  position: absolute;
  top: 403px;
  left: calc(50% - 380px);
  width: 761px;
  height: 582px;
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
}
.submit-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-teal-200);
  width: 186px;
  height: 46px;
}
.submit2 {
  position: absolute;
  top: 0;
  left: 43px;
  line-height: 150%;
}
.submit1 {
  text-decoration: none;
  position: absolute;
  top: 921px;
  left: 628px;
  width: 186px;
  height: 46px;
  cursor: pointer;
  color: var(--static-static-white);
}
.org-success {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.pricing3 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.pricing-container {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar3 {
  position: absolute;
  top: 11px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
}
.attendee-btn-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-darkslategray-100);
  width: 326px;
  height: 136px;
}
.attendee {
  position: absolute;
  top: 44px;
  left: 95px;
  line-height: 150%;
  font-weight: 100;
}
.attendee-btn,
.attendee-btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 326px;
  height: 136px;
}
.attendee-btn-wrapper {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.organizer {
  position: absolute;
  top: 44px;
  left: 92px;
  line-height: 150%;
  font-weight: 100;
}
.frame-group,
.organizer-btn {
  position: absolute;
  height: 136px;
}
.organizer-btn {
  cursor: pointer;
  text-decoration: none;
  top: 0;
  left: 424px;
  width: 326px;
  color: inherit;
}
.frame-group {
  top: 444px;
  left: 345px;
  width: 750px;
  color: var(--static-static-white);
}
.you-are {
  position: absolute;
  top: 257px;
  left: calc(50% - 63px);
  line-height: 150%;
  font-weight: 100;
}
.synclyfy-v1-1-icon2 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-container {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 532px);
  left: -4px;
  width: 392px;
  height: 200px;
}
.signup {
  width: 100%;
  position: relative;
  background-color: #d8e7ff;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.form-child2 {
  position: absolute;
  top: 0;
  left: calc(50% - 380.5px);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-600);
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 761px;
  height: 582px;
}
.what-features-of1 {
  position: absolute;
  top: 22px;
  left: 127px;
  line-height: 150%;
}
.form-child3,
.form-child4,
.form-child5,
.form-child6 {
  border: 1px solid var(--color-teal-200);
  outline: 0;
  background-color: transparent;
  position: absolute;
  top: 59px;
  left: 56px;
  border-radius: var(--br-xl);
  box-sizing: border-box;
  width: 620px;
  height: 66px;
}
.form-child4,
.form-child5,
.form-child6 {
  top: 175px;
}
.form-child5,
.form-child6 {
  top: 291px;
}
.form-child6 {
  top: 411px;
}
.how-likely-are {
  position: absolute;
  top: 374px;
  left: 135px;
  line-height: 150%;
}
.are-there-any1,
.is-there-anything {
  position: absolute;
  top: 142px;
  left: 94px;
  line-height: 150%;
}
.are-there-any1 {
  top: 260px;
  left: 126px;
}
.form2 {
  position: absolute;
  top: 403px;
  left: calc(50% - 380px);
  width: 761px;
  height: 582px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}

.success-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing4 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.pricing-frame {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar4 {
  position: absolute;
  top: 10px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-200);
  width: 1376px;
  height: 138px;
  font-size: var(--font-size-13xl);
}
.synclyfy-v1-1-icon3 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.group-a,
.synclyfyv2-1-icon3 {
  position: absolute;
  left: 0;
  height: 200px;
}
.synclyfyv2-1-icon3 {
  top: 0;
  width: 200px;
  overflow: hidden;
  object-fit: cover;
}
.group-a {
  cursor: pointer;
  text-decoration: none;
  top: calc(50% - 533px);
  width: 392px;
}
.success-youve-secured1 {
  position: absolute;
  top: 158px;
  left: calc(50% - 331px);
  line-height: 150%;
  display: inline-block;
  width: 662px;
}
.check-circle-icon1 {
  position: absolute;
  top: 251px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.wed-love-your1 {
  position: absolute;
  top: 362px;
  left: 340px;
  font-size: var(--font-size-xl);
  line-height: 150%;
  display: inline-block;
  width: 831px;
}
.submit-item,
.submit4 {
  position: absolute;
  top: 0;
}
.submit-item {
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-teal-200);
  width: 186px;
  height: 46px;
}
.submit4 {
  left: 43px;
  line-height: 150%;
}
.submit3 {
  text-decoration: none;
  position: absolute;
  top: 921px;
  left: 628px;
  width: 186px;
  height: 46px;
  cursor: pointer;
  color: var(--static-static-white);
}
.success {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.pricing8 {
  position: absolute;
  top: 44px;
  left: 1005px;
  line-height: 150%;
  cursor: pointer;
}
.nav-bar17 {
  position: absolute;
  top: 31px;
  left: calc(50% - 666px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.articles-learn-more-child {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    117deg,
    rgba(0, 117, 129, 0.84),
    rgba(20, 116, 105, 0.84)
  );
  width: 1440px;
  height: 1024px;
}
.unlock-exclusive-access {
  position: absolute;
  top: 393px;
  left: calc(50% - 626px);
  line-height: 150%;
  display: inline-block;
  text-align: center;
  width: 1253px;
}
.coming-soon {
  position: absolute;
  top: 282px;
  left: calc(50% - 189px);
  line-height: 150%;
}
.synclyfy-v1-1-icon4 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent1 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 100px);
  left: 0;
  width: 392px;
  height: 200px;
}
.nav-bar5 {
  position: absolute;
  top: -20px;
  left: -12px;
  width: 1420px;
  height: 200px;
}
.join-child {
  position: absolute;
  top: 0;
  left: calc(50% - 84.5px);
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 169px;
  height: 61px;
}
.join-now {
  position: absolute;
  top: 6px;
  left: calc(50% - 67.5px);
  line-height: 150%;
}
.join,
.join-wrapper {
  position: absolute;
  top: 0;
  left: calc(50% - 84.5px);
  width: 169px;
  height: 61px;
}
.join-wrapper {
  cursor: pointer;
  text-decoration: none;
  top: 797px;
  left: calc(50% - 84px);
  font-size: var(--font-size-13xl);
  color: inherit;
  font-family: var(--font-roboto);
}
.articles-learn-more {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}

.email-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 536px;
  height: 69px;
}
.email-item,
.email4,
.email5 {
  position: absolute;
}
.email5 {
  top: 23px;
  left: 32px;
  line-height: 150%;
  font-weight: 100;
}
.email4 {
  top: 0;
  left: 0;
  width: 536px;
  height: 69px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}

.organizer-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing5 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.frame-a {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar6 {
  position: absolute;
  top: 11px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
  color: var(--text-primary);
}
.synclyfy-v1-1-icon5 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent2 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 532px);
  left: -17px;
  width: 392px;
  height: 200px;
}
.beta-access-is {
  position: absolute;
  top: 232px;
  left: calc(50% - 564px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 1129px;
}
.group-child46,
.name-item {
  border: 1px solid var(--color-teal-200);
  position: absolute;
  top: 0;
  border-radius: var(--br-31xl);
  box-sizing: border-box;
}
.group-child46 {
  left: calc(50% - 330px);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  background-color: var(--color-gainsboro-600);
  width: 660px;
  height: 369px;
}
.name-item {
  outline: 0;
  background-color: transparent;
  left: 0;
  width: 536px;
  height: 44px;
}
.pricing-plan {
  position: absolute;
  top: 10px;
  left: 32px;
  line-height: 150%;
  font-weight: 100;
}
.name2 {
  position: absolute;
  top: 66px;
  left: 0;
  width: 536px;
  height: 44px;
}
.join-item,
.name3 {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
}
.name3 {
  outline: 0;
  display: block;
  font-weight: 100;
  font-family: var(--font-saira);
  font-size: var(--font-size-mini);
  background-color: transparent;
  border-radius: var(--br-31xl);
  width: 536px;
  height: 44px;
  padding: var(--padding-3xs) var(--padding-13xl) var(--padding-2xs);
  font-style: italic;
  color: var(--color-teal-200);
}
.join-item {
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 113px;
  height: 55px;
}
.join2 {
  border: 0;
  outline: 0;
  font-family: var(--font-saira);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  position: absolute;
  top: 5px;
  left: 30px;
  line-height: 150%;
  color: var(--static-static-white);
  text-align: left;
  text-shadow: 1px 0 0#007581, 0 1px 0#007581, -1px 0 0#007581, 0-1px 0#007581;
}
.join-container,
.join1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 113px;
  height: 55px;
}
.join-container {
  text-decoration: none;
  top: 7px;
  left: 414px;
  cursor: pointer;
}
.email2,
.name-parent {
  position: absolute;
  top: 142px;
  left: 0;
  width: 536px;
  height: 69px;
}
.name-parent {
  top: 79px;
  left: 62px;
  height: 211px;
}
.rectangle-parent1 {
  position: absolute;
  top: 435px;
  left: calc(50% - 330px);
  width: 660px;
  height: 369px;
  font-size: var(--font-size-mini);
}
.organizer1 {
  width: 100%;
  position: relative;
  background-color: var(--color-aliceblue);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}

.attendee-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing6 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.nav-bar7,
.pricing-wrapper1 {
  position: absolute;
  cursor: pointer;
}
.pricing-wrapper1 {
  text-decoration: none;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar7 {
  top: 11px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
}
.synclyfy-v1-1-icon6 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent3 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 532px);
  left: -17px;
  width: 392px;
  height: 200px;
}
.beta-access-is1 {
  position: absolute;
  top: 232px;
  left: calc(50% - 564px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
  color: var(--color-teal-200);
  display: inline-block;
  width: 1129px;
}
.group-child47,
.join-inner,
.name4 {
  position: absolute;
  top: 0;
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
}
.group-child47 {
  left: calc(50% - 330px);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-600);
  width: 660px;
  height: 369px;
}
.join-inner,
.name4 {
  left: 0;
}
.name4 {
  outline: 0;
  display: block;
  font-weight: 100;
  font-family: var(--font-saira);
  font-size: var(--font-size-mini);
  background-color: transparent;
  border-radius: var(--br-31xl);
  width: 536px;
  height: 44px;
  padding: var(--padding-3xs) var(--padding-13xl) var(--padding-2xs);
  font-style: italic;
  color: var(--color-teal-200);
}
.join-inner {
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 113px;
  height: 55px;
}
.join4 {
  border: 0;
  outline: 0;
  font-family: var(--font-saira);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  position: absolute;
  top: 5px;
  left: 30px;
  line-height: 150%;
  color: var(--static-static-white);
  text-align: left;
  text-shadow: 1px 0 0#007581, 0 1px 0#007581, -1px 0 0#007581, 0-1px 0#007581;
}
.join-frame,
.join3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 113px;
  height: 55px;
}
.join-frame {
  cursor: pointer;
  text-decoration: none;
  top: 7px;
  left: 414px;
}
.email3,
.name-group {
  position: absolute;
  top: 76px;
  left: 0;
  width: 536px;
  height: 69px;
}
.name-group {
  top: 112px;
  left: 62px;
  height: 145px;
}
.rectangle-parent2 {
  position: absolute;
  top: 435px;
  left: calc(50% - 330px);
  width: 660px;
  height: 369px;
}
.attendee1 {
  width: 100%;
  position: relative;
  background-color: var(--color-aliceblue);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

.org-success-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing7 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.frame-div {
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  cursor: pointer;
}
.nav-bar8 {
  position: absolute;
  top: 10px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-200);
  width: 1376px;
  height: 138px;
}
.org-success-inner {
  position: absolute;
  top: calc(50% - 533px);
  left: 0;
  width: 392px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}
.thank-you-for {
  position: absolute;
  top: calc(50% - 103.5px);
  left: calc(50% - 331px);
  line-height: 150%;
  display: inline-block;
  width: 662px;
}
.check-circle-icon2 {
  position: absolute;
  top: calc(50% + 3.5px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.thank-you-for-your-feedback-y-parent {
  position: absolute;
  top: calc(50% - 104px);
  left: calc(50% - 331px);
  width: 662px;
  height: 207px;
  font-size: var(--font-size-11xl);
}
.org-success1 {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-saira: Saira;
  --font-roboto: Roboto;
  --font-ruda: Ruda;
  --font-montserrat: Montserrat;
  --font-poppins: Poppins;
  --virtual-event-footnote: Inter;
  --font-sf-pro-display: "SF Pro Display";
  --font-sf-pro: "SF Pro";

  /* font sizes */
  --font-size-13xl: 32px;
  --font-size-mini: 15px;
  --font-size-mid: 17px;
  --font-size-xl: 20px;
  --font-size-31xl: 50px;
  --font-size-6xl: 25px;
  --font-size-11xl: 30px;
  --font-size-41xl: 60px;
  --font-size-21xl: 40px;
  --font-size-lg: 18px;
  --font-size-base: 16px;
  --font-size-46xl: 65px;
  --font-size-smi: 13px;
  --font-size-3xs: 10px;
  --virtual-event-footnote-size: 12px;
  --virtual-event-body-size: 14px;

  /* Colors */
  --color-lavender-100: #e0ecff;
  --color-lavender-200: #dae8ff;
  --text-primary: #000;
  --static-static-white: #fff;
  --color-teal-100: #147469;
  --color-teal-200: #007581;
  --color-teal-500: #00717c;
  --color-teal-600: #006c77;
  --color-teal-700: rgba(0, 117, 129, 0.3);
  --default-light-stroke: #e5e5e6;
  --color-gainsboro-100: #e6e3e3;
  --grey-border: #e0e0e0;
  --color-gainsboro-200: #d9d9d9;
  --color-gainsboro-300: rgba(217, 217, 217, 0.1);
  --color-gainsboro-600: rgba(217, 217, 217, 0.15);
  --color-gainsboro-400: rgba(217, 217, 217, 0);
  --color-gainsboro-500: rgba(224, 227, 227, 0.25);
  --color-whitesmoke-400: #eee;
  --color-whitesmoke-200: #ebebeb;
  --color-whitesmoke-300: rgba(233, 235, 235, 0.75);
  --color-gray-300: #7f7f7f;
  --static-static-dark-grey: #0e1420;
  --color-gray-500: #0c171a;
  --color-gray-800: rgba(0, 0, 0, 0.85);
  --color-gray-700: rgba(255, 255, 255, 0.86);
  --color-gray-900: rgba(255, 255, 255, 0.15);
  --color-gray-1300: rgba(255, 255, 255, 0.1);
  --color-gray-1100: rgba(0, 0, 0, 0.8);
  --color-gray-1200: rgba(0, 0, 0, 0.15);
  --color-cornflowerblue: #7d8fd0;
  --color-brown: #9a3d3d;
  --color-darkslateblue-100: #885086;
  --color-darkslateblue-200: #654181;
  --color-cadetblue-200: #508588;
  --color-steelblue: #576197;
  --color-darkslategray-100: #37404d;
  --color-darkslategray-400: #153457;
  --color-darkslategray-500: #263238;
  --color-rosybrown-100: rgba(197, 158, 177, 0.33);
  --default-light-text-low-emphasis: #b2b1b5;
  --grey-placeholder: #a5acb8;
  --color-darkgray-300: #a4a5aa;
  --color-aliceblue: #eaf2ff;
  --color-blueviolet: #9747ff;
  --static-accent-orange-low-emphasis: #fbca6a;
  --color-slategray-100: #727f96;
  --grey-labels: #4f5b76;
  --default-light-primary-accent: #006cff;
  --stripe-blue: #0570de;

  /* Spacing */
  --radius-full: 9999px;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-3xs: 10px;
  --gap-lgi: 19px;
  --gap-6xs: 7px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-4xs: 9px;
  --padding-mid: 17px;
  --padding-3xs: 10px;
  --padding-13xl: 32px;
  --padding-2xs: 11px;
  --padding-11xs: 2px;
  --padding-sm: 14px;
  --padding-base: 16px;
  --padding-12xs: 1px;
  --padding-7xs: 6px;
  --padding-9xs: 4px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-smi: 13px;
  --padding-10xs: 3px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-11xl: 30px;
  --br-3xs: 10px;
  --br-23xl: 42px;
  --br-31xl: 50px;
  --br-27xl: 46px;
  --br-131xl: 150px;
  --br-9980xl: 9999px;
  --br-21xl: 40px;
  --br-81xl: 100px;
  --br-42xl: 61px;
  --br-5xl: 24px;
  --br-8xs: 5px;
  --br-41xl: 60px;
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-11xs: 2px;
  --br-80xl: 99px;

  /* Effects */
  --blur-glass: blur(4px);
  --inputs: 0px 2px 4px rgba(0, 0, 0, 0.07);
}

