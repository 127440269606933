.pricing3 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.pricing-container {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar3 {
  position: absolute;
  top: 11px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
}
.attendee-btn-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-darkslategray-100);
  width: 326px;
  height: 136px;
}
.attendee {
  position: absolute;
  top: 44px;
  left: 95px;
  line-height: 150%;
  font-weight: 100;
}
.attendee-btn,
.attendee-btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 326px;
  height: 136px;
}
.attendee-btn-wrapper {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.organizer {
  position: absolute;
  top: 44px;
  left: 92px;
  line-height: 150%;
  font-weight: 100;
}
.frame-group,
.organizer-btn {
  position: absolute;
  height: 136px;
}
.organizer-btn {
  cursor: pointer;
  text-decoration: none;
  top: 0;
  left: 424px;
  width: 326px;
  color: inherit;
}
.frame-group {
  top: 444px;
  left: 345px;
  width: 750px;
  color: var(--static-static-white);
}
.you-are {
  position: absolute;
  top: 257px;
  left: calc(50% - 63px);
  line-height: 150%;
  font-weight: 100;
}
.synclyfy-v1-1-icon2 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-container {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 532px);
  left: -4px;
  width: 392px;
  height: 200px;
}
.signup {
  width: 100%;
  position: relative;
  background-color: #d8e7ff;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
