.organizer-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing5 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.frame-a {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar6 {
  position: absolute;
  top: 11px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
  color: var(--text-primary);
}
.synclyfy-v1-1-icon5 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent2 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 532px);
  left: -17px;
  width: 392px;
  height: 200px;
}
.beta-access-is {
  position: absolute;
  top: 232px;
  left: calc(50% - 564px);
  font-size: var(--font-size-41xl);
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 1129px;
}
.group-child46,
.name-item {
  border: 1px solid var(--color-teal-200);
  position: absolute;
  top: 0;
  border-radius: var(--br-31xl);
  box-sizing: border-box;
}
.group-child46 {
  left: calc(50% - 330px);
  backdrop-filter: blur(15px);
  background-color: var(--color-gainsboro-600);
  width: 660px;
  height: 369px;
}
.name-item {
  outline: 0;
  background-color: transparent;
  left: 0;
  width: 536px;
  height: 44px;
}
.pricing-plan {
  position: absolute;
  top: 10px;
  left: 32px;
  line-height: 150%;
  font-weight: 100;
}
.name2 {
  position: absolute;
  top: 66px;
  left: 0;
  width: 536px;
  height: 44px;
}
.join-item,
.name3 {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
}
.name3 {
  outline: 0;
  display: block;
  font-weight: 100;
  font-family: var(--font-saira);
  font-size: var(--font-size-mini);
  background-color: transparent;
  border-radius: var(--br-31xl);
  width: 536px;
  height: 44px;
  padding: var(--padding-3xs) var(--padding-13xl) var(--padding-2xs);
  font-style: italic;
  color: var(--color-teal-200);
}
.join-item {
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 113px;
  height: 55px;
}
.join2 {
  border: 0;
  outline: 0;
  font-family: var(--font-saira);
  font-size: var(--font-size-11xl);
  background-color: transparent;
  position: absolute;
  top: 5px;
  left: 30px;
  line-height: 150%;
  color: var(--static-static-white);
  text-align: left;
  text-shadow: 1px 0 0#007581, 0 1px 0#007581, -1px 0 0#007581, 0-1px 0#007581;
}
.join-container,
.join1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 113px;
  height: 55px;
}
.join-container {
  text-decoration: none;
  top: 7px;
  left: 414px;
  cursor: pointer;
}
.email2,
.name-parent {
  position: absolute;
  top: 142px;
  left: 0;
  width: 536px;
  height: 69px;
}
.name-parent {
  top: 79px;
  left: 62px;
  height: 211px;
}
.rectangle-parent1 {
  position: absolute;
  top: 435px;
  left: calc(50% - 330px);
  width: 660px;
  height: 369px;
  font-size: var(--font-size-mini);
}
.organizer1 {
  width: 100%;
  position: relative;
  background-color: var(--color-aliceblue);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}
