.pricing8 {
  position: absolute;
  top: 44px;
  left: 1005px;
  line-height: 150%;
  cursor: pointer;
}
.nav-bar17 {
  position: absolute;
  top: 31px;
  left: calc(50% - 666px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-100);
  width: 1376px;
  height: 138px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
