.group-child103,
.rectangle-wrapper1 {
  position: absolute;
  width: 1448px;
  height: 1265px;
}
.group-child103 {
  top: 0;
  left: 0;
  background-color: var(--text-primary);
}
.rectangle-wrapper1 {
  top: 10px;
  left: 10px;
}
