@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ruda:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=SF+Pro:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-saira: Saira;
  --font-roboto: Roboto;
  --font-ruda: Ruda;
  --font-montserrat: Montserrat;
  --font-poppins: Poppins;
  --virtual-event-footnote: Inter;
  --font-sf-pro-display: "SF Pro Display";
  --font-sf-pro: "SF Pro";

  /* font sizes */
  --font-size-13xl: 32px;
  --font-size-mini: 15px;
  --font-size-mid: 17px;
  --font-size-xl: 20px;
  --font-size-31xl: 50px;
  --font-size-6xl: 25px;
  --font-size-11xl: 30px;
  --font-size-41xl: 60px;
  --font-size-21xl: 40px;
  --font-size-lg: 18px;
  --font-size-base: 16px;
  --font-size-46xl: 65px;
  --font-size-smi: 13px;
  --font-size-3xs: 10px;
  --virtual-event-footnote-size: 12px;
  --virtual-event-body-size: 14px;

  /* Colors */
  --color-lavender-100: #e0ecff;
  --color-lavender-200: #dae8ff;
  --text-primary: #000;
  --static-static-white: #fff;
  --color-teal-100: #147469;
  --color-teal-200: #007581;
  --color-teal-500: #00717c;
  --color-teal-600: #006c77;
  --color-teal-700: rgba(0, 117, 129, 0.3);
  --default-light-stroke: #e5e5e6;
  --color-gainsboro-100: #e6e3e3;
  --grey-border: #e0e0e0;
  --color-gainsboro-200: #d9d9d9;
  --color-gainsboro-300: rgba(217, 217, 217, 0.1);
  --color-gainsboro-600: rgba(217, 217, 217, 0.15);
  --color-gainsboro-400: rgba(217, 217, 217, 0);
  --color-gainsboro-500: rgba(224, 227, 227, 0.25);
  --color-whitesmoke-400: #eee;
  --color-whitesmoke-200: #ebebeb;
  --color-whitesmoke-300: rgba(233, 235, 235, 0.75);
  --color-gray-300: #7f7f7f;
  --static-static-dark-grey: #0e1420;
  --color-gray-500: #0c171a;
  --color-gray-800: rgba(0, 0, 0, 0.85);
  --color-gray-700: rgba(255, 255, 255, 0.86);
  --color-gray-900: rgba(255, 255, 255, 0.15);
  --color-gray-1300: rgba(255, 255, 255, 0.1);
  --color-gray-1100: rgba(0, 0, 0, 0.8);
  --color-gray-1200: rgba(0, 0, 0, 0.15);
  --color-cornflowerblue: #7d8fd0;
  --color-brown: #9a3d3d;
  --color-darkslateblue-100: #885086;
  --color-darkslateblue-200: #654181;
  --color-cadetblue-200: #508588;
  --color-steelblue: #576197;
  --color-darkslategray-100: #37404d;
  --color-darkslategray-400: #153457;
  --color-darkslategray-500: #263238;
  --color-rosybrown-100: rgba(197, 158, 177, 0.33);
  --default-light-text-low-emphasis: #b2b1b5;
  --grey-placeholder: #a5acb8;
  --color-darkgray-300: #a4a5aa;
  --color-aliceblue: #eaf2ff;
  --color-blueviolet: #9747ff;
  --static-accent-orange-low-emphasis: #fbca6a;
  --color-slategray-100: #727f96;
  --grey-labels: #4f5b76;
  --default-light-primary-accent: #006cff;
  --stripe-blue: #0570de;

  /* Spacing */
  --radius-full: 9999px;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-3xs: 10px;
  --gap-lgi: 19px;
  --gap-6xs: 7px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-4xs: 9px;
  --padding-mid: 17px;
  --padding-3xs: 10px;
  --padding-13xl: 32px;
  --padding-2xs: 11px;
  --padding-11xs: 2px;
  --padding-sm: 14px;
  --padding-base: 16px;
  --padding-12xs: 1px;
  --padding-7xs: 6px;
  --padding-9xs: 4px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-smi: 13px;
  --padding-10xs: 3px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-11xl: 30px;
  --br-3xs: 10px;
  --br-23xl: 42px;
  --br-31xl: 50px;
  --br-27xl: 46px;
  --br-131xl: 150px;
  --br-9980xl: 9999px;
  --br-21xl: 40px;
  --br-81xl: 100px;
  --br-42xl: 61px;
  --br-5xl: 24px;
  --br-8xs: 5px;
  --br-41xl: 60px;
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-11xs: 2px;
  --br-80xl: 99px;

  /* Effects */
  --blur-glass: blur(4px);
  --inputs: 0px 2px 4px rgba(0, 0, 0, 0.07);
}
