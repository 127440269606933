.articles-learn-more-child {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    117deg,
    rgba(0, 117, 129, 0.84),
    rgba(20, 116, 105, 0.84)
  );
  width: 1440px;
  height: 1024px;
}
.unlock-exclusive-access {
  position: absolute;
  top: 393px;
  left: calc(50% - 626px);
  line-height: 150%;
  display: inline-block;
  text-align: center;
  width: 1253px;
}
.coming-soon {
  position: absolute;
  top: 282px;
  left: calc(50% - 189px);
  line-height: 150%;
}
.synclyfy-v1-1-icon4 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-parent1 {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 100px);
  left: 0;
  width: 392px;
  height: 200px;
}
.nav-bar5 {
  position: absolute;
  top: -20px;
  left: -12px;
  width: 1420px;
  height: 200px;
}
.join-child {
  position: absolute;
  top: 0;
  left: calc(50% - 84.5px);
  border-radius: var(--br-11xl);
  background-color: var(--color-teal-200);
  width: 169px;
  height: 61px;
}
.join-now {
  position: absolute;
  top: 6px;
  left: calc(50% - 67.5px);
  line-height: 150%;
}
.join,
.join-wrapper {
  position: absolute;
  top: 0;
  left: calc(50% - 84.5px);
  width: 169px;
  height: 61px;
}
.join-wrapper {
  cursor: pointer;
  text-decoration: none;
  top: 797px;
  left: calc(50% - 84px);
  font-size: var(--font-size-13xl);
  color: inherit;
  font-family: var(--font-roboto);
}
.articles-learn-more {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}
