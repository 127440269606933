.org-success-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pricing2 {
  position: absolute;
  top: 6px;
  left: 34px;
  line-height: 150%;
}
.pricing-wrapper {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 38px;
  left: 1185px;
  width: 169px;
  height: 61px;
  color: inherit;
}
.nav-bar2 {
  position: absolute;
  top: 10px;
  left: calc(50% - 688px);
  border-radius: var(--br-xl);
  background-color: var(--color-lavender-200);
  width: 1376px;
  height: 138px;
  font-size: var(--font-size-13xl);
}
.synclyfy-v1-1-icon1 {
  position: absolute;
  top: calc(50% - 36.4px);
  left: 52px;
  width: 340px;
  height: 74.8px;
  overflow: hidden;
}
.synclyfyv2-1-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
}
.synclyfy-v1-1-group {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: calc(50% - 533px);
  left: 0;
  width: 392px;
  height: 200px;
}
.success-youve-secured {
  position: absolute;
  top: 158px;
  left: calc(50% - 331px);
  line-height: 150%;
  display: inline-block;
  width: 662px;
}
.check-circle-icon {
  position: absolute;
  top: 251px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.wed-love-your {
  position: absolute;
  top: 362px;
  left: 340px;
  font-size: var(--font-size-xl);
  line-height: 150%;
  display: inline-block;
  width: 831px;
}
.form-child {
  position: absolute;
  top: 0;
  left: calc(50% - 380.5px);
  backdrop-filter: blur(20px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gainsboro-600);
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 761px;
  height: 582px;
}
.what-features-of {
  position: absolute;
  top: 22px;
  left: calc(50% - 330.5px);
  line-height: 150%;
}
.form-child1,
.form-inner,
.form-item,
.rectangle-input {
  border: 1px solid var(--color-teal-200);
  outline: 0;
  background-color: transparent;
  position: absolute;
  top: 59px;
  left: 56px;
  border-radius: var(--br-xl);
  box-sizing: border-box;
  width: 620px;
  height: 66px;
}
.form-child1,
.form-inner,
.rectangle-input {
  top: 175px;
}
.form-child1,
.rectangle-input {
  top: 291px;
}
.form-child1 {
  top: 434px;
}
.how-do-you,
.how-does-synclyfy {
  position: absolute;
  line-height: 150%;
}
.how-do-you {
  top: 365px;
  left: calc(50% - 343.5px);
  text-align: center;
  display: inline-block;
  width: 688px;
}
.how-does-synclyfy {
  top: 142px;
  left: calc(50% - 347.5px);
}
.are-there-any {
  position: absolute;
  top: 260px;
  left: 126px;
  line-height: 150%;
}
.form {
  position: absolute;
  top: 403px;
  left: calc(50% - 380px);
  width: 761px;
  height: 582px;
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
}
.submit-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-teal-200);
  width: 186px;
  height: 46px;
}
.submit2 {
  position: absolute;
  top: 0;
  left: 43px;
  line-height: 150%;
}
.submit1 {
  text-decoration: none;
  position: absolute;
  top: 921px;
  left: 628px;
  width: 186px;
  height: 46px;
  cursor: pointer;
  color: var(--static-static-white);
}
.org-success {
  width: 100%;
  position: relative;
  background-color: var(--static-static-white);
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--text-primary);
  font-family: var(--font-saira);
}
