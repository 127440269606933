.email-item {
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  border: 1px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 536px;
  height: 69px;
}
.email-item,
.email4,
.email5 {
  position: absolute;
}
.email5 {
  top: 23px;
  left: 32px;
  line-height: 150%;
  font-weight: 100;
}
.email4 {
  top: 0;
  left: 0;
  width: 536px;
  height: 69px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-teal-200);
  font-family: var(--font-saira);
}
