.shapes-1-icon6,
.uni-commun-child6 {
  position: absolute;
  top: 0;
  left: 470px;
  width: 912.6px;
  height: 1166.1px;
  object-fit: contain;
}
.uni-commun-child6 {
  left: 473.4px;
  object-fit: cover;
}
.uni-commun-child7,
.uni-commun-child8,
.uni-commun-child9 {
  position: absolute;
  top: 244.4px;
  left: 656.2px;
  border-radius: var(--br-11xl);
  background-color: var(--color-darkslateblue-200);
  width: 685.3px;
  height: 94.8px;
}
.uni-commun-child8,
.uni-commun-child9 {
  top: 359.8px;
  background-color: var(--color-steelblue);
  width: 431.7px;
}
.uni-commun-child9 {
  top: 474px;
  background-color: var(--color-cadetblue-200);
  width: 569.9px;
}
.uni-commun-child10,
.uni-commun-child11,
.uni-commun-child12,
.uni-commun-child13 {
  position: absolute;
  top: 589.3px;
  left: 656.2px;
  border-radius: var(--br-11xl);
  background-color: var(--color-darkslateblue-100);
  width: 657.9px;
  height: 94.8px;
}
.uni-commun-child11,
.uni-commun-child12,
.uni-commun-child13 {
  top: 704.7px;
  background-color: var(--color-brown);
  width: 569.9px;
}
.uni-commun-child12,
.uni-commun-child13 {
  top: 820.1px;
  background-color: var(--color-gray-1100);
  width: 323.2px;
}
.uni-commun-child13 {
  top: 935.4px;
  background-color: var(--color-cornflowerblue);
  width: 185px;
}
.unsplashieebwgy-6la-icon46,
.unsplashieebwgy-6la-icon47,
.unsplashieebwgy-6la-icon48 {
  position: absolute;
  top: 238.7px;
  left: 546.5px;
  border-radius: var(--br-27xl);
  width: 87.9px;
  height: 86.8px;
  object-fit: cover;
}
.unsplashieebwgy-6la-icon47,
.unsplashieebwgy-6la-icon48 {
  top: 704.7px;
  height: 87.9px;
}
.unsplashieebwgy-6la-icon48 {
  top: 820.1px;
}
.unsplashieebwgy-6la-icon49,
.unsplashieebwgy-6la-icon50,
.unsplashieebwgy-6la-icon51,
.unsplashieebwgy-6la-icon52 {
  position: absolute;
  top: 356.3px;
  left: 546.5px;
  border-radius: var(--br-27xl);
  width: 87.9px;
  height: 87.9px;
  object-fit: cover;
}
.unsplashieebwgy-6la-icon50,
.unsplashieebwgy-6la-icon51,
.unsplashieebwgy-6la-icon52 {
  top: 935.4px;
  height: 86.8px;
}
.unsplashieebwgy-6la-icon51,
.unsplashieebwgy-6la-icon52 {
  top: 470.6px;
}
.unsplashieebwgy-6la-icon52 {
  top: 597.3px;
}
.check-out-bootstrap1,
.count-me-in1,
.hey-any-recommendations1,
.im-in-for1,
.im-into-front-end1 {
  position: absolute;
  top: 273px;
  left: 683.6px;
  line-height: 150%;
  display: inline-block;
  width: 641.9px;
  height: 35.4px;
}
.check-out-bootstrap1,
.count-me-in1,
.im-in-for1,
.im-into-front-end1 {
  top: 503.7px;
  left: 677.9px;
  width: 538px;
}
.check-out-bootstrap1,
.count-me-in1,
.im-in-for1 {
  top: 619px;
  width: 616.8px;
  height: 34.3px;
}
.count-me-in1,
.im-in-for1 {
  top: 848.6px;
  left: 676.7px;
  width: 290.1px;
  height: 35.4px;
}
.im-in-for1 {
  top: 966.3px;
  width: 151.9px;
  height: 34.3px;
}
.by-the-way1 {
  margin: 0;
}
.by-the-way-container1,
.try-contributing-to1 {
  position: absolute;
  top: 716.1px;
  left: 683.6px;
  line-height: 150%;
  display: inline-block;
  width: 532.2px;
  height: 70.8px;
}
.try-contributing-to1 {
  top: 388.3px;
  width: 368.9px;
  height: 35.4px;
}
.chat-footer-child4 {
  position: absolute;
  top: 0;
  left: 155.4px;
  backdrop-filter: blur(20px);
  border-radius: var(--br-31xl);
  background-color: var(--color-gray-1200);
  width: 563.8px;
  height: 58px;
}
.message7 {
  position: absolute;
  top: 11.6px;
  left: 196px;
  line-height: 150%;
  display: inline-block;
  width: 99.8px;
  height: 34.8px;
}
.image-1-icon6,
.mic-1-icon6,
.plus-thin-16 {
  position: absolute;
  top: 0;
  left: 0;
  width: 58px;
  height: 58px;
  overflow: hidden;
}
.image-1-icon6,
.mic-1-icon6 {
  left: 738.9px;
}
.image-1-icon6 {
  left: 77.7px;
}
.chat-footer6,
.nav-bar-icon1 {
  position: absolute;
  top: 1072px;
  left: 547px;
  width: 796.9px;
  height: 58px;
}
.nav-bar-icon1 {
  top: 46px;
  left: 625px;
  width: 641px;
  height: 121px;
  object-fit: contain;
}
.satck-item,
.techstack-item {
  position: absolute;
  top: 83.8px;
  left: 0;
  background-color: var(--color-gainsboro-400);
  width: 426px;
  height: 379px;
}
.techstack-item {
  text-decoration: none;
  top: 0;
  backdrop-filter: blur(16.1px);
  border-radius: var(--br-23xl);
  background-color: var(--color-gainsboro-500);
  border: 2px solid var(--color-whitesmoke-300);
  box-sizing: border-box;
  width: 400px;
  height: 100px;
}
.tech-stack-community3 {
  position: absolute;
  top: 26px;
  left: 34px;
  font-size: var(--font-size-13xl);
  line-height: 150%;
  font-weight: 600;
  font-family: var(--font-saira);
  color: var(--color-teal-200);
  text-align: left;
}
.frame-child36,
.techstack1 {
  position: absolute;
  height: 100px;
}
.techstack1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 174.8px;
  left: 6.1px;
  width: 409.6px;
}
.frame-child36 {
  top: 0;
  left: 0;
  backdrop-filter: blur(16.1px);
  border-radius: var(--br-23xl);
  background-color: var(--color-gainsboro-500);
  border: 2px solid var(--color-whitesmoke-300);
  box-sizing: border-box;
  width: 400px;
}
.organizer-community5 {
  position: absolute;
  top: 26px;
  left: 37px;
  font-size: var(--font-size-13xl);
  line-height: 150%;
  font-weight: 600;
  font-family: var(--font-saira);
  color: var(--color-teal-200);
  text-align: left;
}
.rectangle-parent21 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 297px;
  left: 6.1px;
  width: 409.6px;
  height: 100px;
}
.frame-child37,
.satck-inner1 {
  position: absolute;
  top: 0;
  height: 153px;
}
.frame-child37 {
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset,
    0 4px 4px rgba(0, 0, 0, 0.25) inset;
  border-radius: var(--br-23xl);
  background-color: var(--color-teal-200);
  border: 2px solid var(--color-teal-200);
  box-sizing: border-box;
  width: 400px;
}
.satck-inner1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  left: 7.2px;
  width: 409.6px;
}
.connect-with-students1,
.university-community3 {
  position: absolute;
  top: 13px;
  left: 41px;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  width: 341px;
}
.connect-with-students1 {
  top: 61px;
  left: 69.6px;
  font-size: var(--font-size-mid);
  font-weight: 300;
  width: 308.2px;
}
.satck1,
.uni-commun1 {
  position: absolute;
  left: 0;
}
.satck1 {
  top: 412px;
  width: 426px;
  height: 462.8px;
  font-size: var(--font-size-13xl);
}
.uni-commun1 {
  top: 142px;
  width: 1386px;
  height: 1166.1px;
}
.attnorg-ui-btn-inner8,
.frame-child38 {
  position: absolute;
  left: 0;
  width: 150px;
  height: 30px;
}
.frame-child38 {
  top: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-teal-200);
}
.attnorg-ui-btn-inner8 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  top: 1px;
}
.attendee-ui7 {
  position: absolute;
  top: 4px;
  left: 34px;
  line-height: 150%;
  font-weight: 100;
}
.frame-child39 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--static-static-white);
  width: 150px;
  height: 30px;
}
.organizer-ui7 {
  position: absolute;
  top: 3px;
  left: 34px;
  line-height: 150%;
  font-weight: 100;
}
.rectangle-parent22 {
  position: absolute;
  top: 0;
  left: 162px;
  width: 150px;
  height: 30px;
  color: var(--color-teal-200);
}
.attnorg-ui-btn10 {
  position: absolute;
  top: 0;
  left: 473px;
  width: 312px;
  height: 31px;
  font-size: var(--font-size-mini);
}
.virtual-community1 {
  position: absolute;
  top: 22638px;
  left: calc(50% - 693px);
  width: 1386px;
  height: 1308.1px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--static-static-white);
  font-family: var(--font-saira);
}
